<template>
    <v-container style="padding-right:15px;">
        <!-- <v-row justify="end align-center"> -->
        <v-row :class="!isMobile ? 'row-desktop' : 'row-mobile'">
            <!-- <v-badge :content="messages" :value="messages" color="green" overlap>
                <img src="@/assets/images/notification-icon.svg" alt="" width="18" height="18" v-if="!isMobile">   
                <img src="@/assets/images/mobile-notif.png" alt="" v-if="isMobile" class="mobile-notif" />
            </v-badge> -->
            <div class="share-feedback-btn mr-5" @click="openDialog()">
                <span class="share-feedback-text">
                    Share Feedback
                </span>
            </div>
            <img src="@/assets/icons/bell.svg"/>           
            <v-menu offset-y v-if="!isMobile">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        style="border:1px solid #EBF2F5;
                               border-radius:4px !important;
                               height: 48px;                               
                               width: 200px !important;"
                        v-bind="attrs"
                        v-on="on"
                        elevation="0"
                        class="avatar-button" >
                         <span class="iconHolder">
                            <img 
                                 class="imgHolder"
                                 src="@/assets/icons/profile-icon.svg">
                         </span>
                        <!-- <v-avatar v-if="!isMobile" size="35">
                            <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>                             
                            <v-icon small class="mr-2"> mdi-account </v-icon> 
                        </v-avatar> -->
                        <span
                            class="textCenter" 
                            style= ""
                            >
                            {{dateEmail}}
                        </span>
                        <span class="arrowDown">
                            <img src="@/assets/icons/profile-icon-arrowdown.svg">
                        </span>
                    </v-btn>
                </template>

                <v-list>
                    <v-list-item>
                        <!-- <button v-for="(item, index) in items" :key="index">{{ item.title }}</button> -->
                        <v-btn @click="userLogout" class="btn-logout"> 
                            <v-icon aria-hidden="false" style="color: #000 !important; margin-right: 5px !important; font-size: 20px !important;"> mdi-logout </v-icon>
                            Logout 
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-row>
        <FeedbackDialog
            :openFeedback="openFeedback"
            @success="successFeedback"
            @closeDialog="closeDialog"
            @closeDialogOnSuccess="closeDialogOnSuccess"
        />
        <FeedbackDialogSuccess
            :openFeedbackSuccess="openFeedbackSuccess"
            @closeDialogSuccess="closeDialogSuccess"
            @closeOnSMF="closeOnSMF"
        />
    </v-container>
</template>

<script>
import { mapActions } from "vuex";
import FeedbackDialog from '../components/Dialog/FeedbackDialog.vue';
import FeedbackDialogSuccess from '../components/Dialog/FeedbackDialogSuccess.vue';
export default {
    name: "Notification",
    props:['isMobile'],
    components: {
        FeedbackDialog,
        FeedbackDialogSuccess
    },
    data() {
        return {
            messages: 0,
            show: false,
            dateEmail: '',
            items: [
                { title: 'Logout' },
            ],
            openFeedback: false,
            openFeedbackSuccess: false
        };
    },
    methods: {
        //
        ...mapActions(["logout"]),
        userLogout() {
            this.$store.dispatch("page/setTab", 1)
            this.logout()
        },
        getEmail(){
            let parse = JSON.parse(localStorage.getItem('shifl.user_details')); 
            if(parse.email !='undefined'){
                this.dateEmail =parse.email.substring(0, 10);
            }           
        },
        openDialog() {
            this.openFeedback = true
        },
        closeDialog() {
            this.openFeedback = false
        },
        closeDialogOnSuccess() {
            this.openFeedback = false
            this.openFeedbackSuccess = true
        },
        closeOnSMF() {
            this.openFeedback = true
            this.openFeedbackSuccess = false
        },
        closeDialogSuccess() {
            this.openFeedbackSuccess = false
        },
        successFeedback() {
            this.openFeedback = false
        }
    },
    mounted(){
        this.getEmail();       
    }
};
</script>

<style scoped>
.row-desktop {
    justify-content: flex-end;
    align-items: center;
}

.mobile-notif {
    margin-top: 4px;
    width: 18px;
}

.avatar-button {
    padding: 0 !important;
    min-width: 35px !important;
    width: 118px !important;
    border-radius: 0% !important;
    margin: 0 5px 0 25px !important;
    background-color: #fff !important;
}

.btn-logout {
    background-color: transparent !important;
    box-shadow: none !important;
    text-transform: capitalize !important;
    letter-spacing: 0;
    font-weight: 600;
}

.avatar-button .v-btn__content .v-avatar i {
    font-size: 20px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 !important;
}

.iconHolder{
    border-radius: 999px;  
    width: 32px !important;
    display: inline-block !important;  
    height: 32px;
    padding-top: 7px;
    position: absolute;
    left: 5px;

    box-sizing: border-box;

/* Grey BG */
background: #EBF2F5;
/* Border Grey */

border: 1px solid #D8E7F0;
border-radius: 999px;

/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}

.arrowDown{
    position: absolute;
    right: 0px;
    padding-right: 9px;
    top: -3px;
}

.imgHolder{
    position: relative;   
    top: 0px;
    width: 15.75px !important;
    height: 18px;
}

.textCenter {
    font-size: 14px;
    position: relative;
    width: 135px;
    min-width: 122px;
    overflow: hidden;
    color: rgb(74, 74, 74) !important;
    font-weight: 600;
    left: 7px;
    text-align: left;
}

.textCenter::after{
    content:'...';
}

.share-feedback-btn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    width: 117px;
    height: 30px;
    left: 955px;
    top: 17px;
    background: #F5F9FC;
    border-radius: 4px;
}
.share-feedback-btn:hover {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px;
    width: 117px;
    height: 30px;
    left: 955px;
    top: 17px;
    background: #cbe6fa;
    border-radius: 4px;
}

.share-feedback-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0171A1;
}
</style>
