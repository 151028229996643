<template>
    <v-dialog persistent v-model="openFeedback" max-width="590px" max-height="600px" content-class="add-supplier-dialog add-driver-dialog" :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-form id="shareFeedback" ref="formFeedback" v-model="valid" action="#" @submit.prevent="" enctype="multipart/form-data">
                <v-card-title style=" caret-color: transparent;">
                    <span class="headline">Share Feedback</span>

                    <button :disabled="isFetching" icon dark class="btn-close" @click="close">
                        <v-icon>mdi-close</v-icon>
                    </button>
                </v-card-title>

                <div class="mb-5 mt-5 mx-6">
                    <div class="mb-3">
                        <label class="text-item-label">Type</label>
                        <div style="    display: flex; gap: 51px;">
                            <div v-for="(type, i) in types" :key="i">
                                <input :value="type.value" type="radio" name="radio" v-model="form.type"/>
                                <div class="control__indicator"></div>
                                <label class="ml-3 radio-text">{{type.value}}</label>
                            </div>
                        </div>
                        <small class="text-red" v-if="errorKeys.includes('type')">This field is required</small>
                    </div>
                    <div class="mb-3">
                        <label class="text-item-label">Subject</label>
                        <v-text-field 
                            v-model="form.subject"
                            type="text"
                            placeholder="Subject" 
                            outlined 
                            class="text-fields"
                        />
                        <small class="text-red" v-if="errorKeys.includes('subject')">This field is required</small>
                    </div>
                    <div class="flex flex-column mb-3">
                        <label class="text-item-label">Comment</label>
                        <textarea 
                            v-model="form.comment"
                            rows="5" 
                            class="textarea-field"
                        >
                        </textarea>
                        <small class="text-red" v-if="errorKeys.includes('comment')">This field is required</small>
                    </div>
                    <div class="mb-3">
                        <label class="text-item-label">Related page</label>
                        <v-text-field 
                            v-model="form.page_related"
                            type="text"
                            placeholder="Related Page" 
                            outlined 
                            class="rp-bg text-fields"
                            :disabled="true"
                        />
                        <small class="text-red" v-if="errorKeys.includes('page_related')">This field is required</small>
                    </div>
                    <div>
                        <label class="text-item-label">Screenshots</label>
                        <div class="flex flex-row flex-wrap">
                            <div
                                style="display: block"
                                class="h-screen items-left justify-left text-left"
                                id="app"
                            >
                                <div
                                    style="width: 100%"
                                    @dragover="dragover"
                                    @dragleave="dragleave"
                                    @drop="dropFile"
                                >
                                    <input
                                        style="display: none"
                                        type="file"
                                        name="fields[]"
                                        id="screenshots"
                                        class="w-px h-px opacity-0 overflow-hidden absolute"
                                        @change="onChange()"
                                        ref="file"
                                        accept="image/*"
                                    />
                                    <label
                                        for="screenshots"
                                        class="block cursor-pointer trigger-field"
                                    >
                                        <div class="flex">
                                            <div class="flex flex-column drop-field">
                                                <div class="browser-text text-center">
                                                    <span>Browse or Drop Image</span>
                                                </div>
                                                <div class="btn-white2 browser-btn">
                                                    <div class="upload-btn mt-2">
                                                    <span class="iconUploadfile"
                                                        ><i class="ic-upload"></i
                                                    ></span>
                                                    <span class="classUpload">Upload</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div class="ml-2">
                                <img :src="base64URL" class="img-ratio" />
                            </div>
                            <div 
                                class="ml-2" 
                                v-for="(file, i) in filelistInURL" 
                                :key="i"
                            >
                                <img :src="file" class="img-ratio" />
                                <button @click="removeFile(i)" class="remove-ss-btn">Remove</button>
                            </div>
                        </div>
                        <!-- <small class="text-red" v-if="errorKeys.includes('files')">Please upload atleast 1 image</small> -->
                    </div>
                </div>
                
                <v-card-actions>
                    <v-btn :disabled="isFetching" @click="handleSubmit" class="btn-blue mr-2" text>
                        <span>
                            <span>
                                <v-progress-circular
                                    :size="15"
                                    color="#fff"
                                    :width="2"
                                    indeterminate
                                    style="margin-right:3px"
                                    v-if="isFetching"
                                >                       
                                </v-progress-circular>
                                Submit
                            </span>
                        </span>
                    </v-btn>                   

                    <v-btn :disabled="isFetching" class="btn-white" @click="close">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import jquery from 'jquery'
import axios from 'axios'
import globalMethods from '../../utils/globalMethods'
import html2canvas from 'html2canvas'

const APIBaseUrl = process.env.VUE_APP_PO_URL
export default {
    name: 'FeedbackDialog',
    props: ["openFeedback"],
    data: () => ({
        form: {
            type: '',
            subject: '',
            comment: '',
            page_related: '',
            files: [],
            base64File: ''
        },
        valid: false,
        isFetching: false,
        errorFile: false,
        errorKeys: [],
        filelist: [],
        filelistInURL: [],
        base64URL: '',
        allFieldsValid: false,
        rules: [
            (v) => {
                if(v === null) {
                    return 'This field is required'   
                }
                else {
                    return false
                }
            }
        ],
        types: [
            {
                value: 'Issue',
                label: 'Issue'
            },
            {
                value: 'Bug',
                label: 'Bug'
            },
            {
                value: 'Feature Request',
                label: 'Feature Request'
            },
            {
                value: 'General Feedback',
                label: 'General Feedback'
            },
        ]
    }),
    watch: {
        filelist(nd) {
            this.filelistInURL = []
            let table = nd;
            let errorCount = 0;

            Object.keys(table).forEach((key) => {
                if (table[key].type.split('/')[0] !== "application" || table[key].type.split('/')[0] !== "video") {
                    console.log(table[key])
                    this.filelistInURL.push(this.createURL(table[key]))
                    errorCount++;
                }
            });
            if (errorCount > 0) {
                this.errorFile = 1;
                this.allFieldsValid = false;
            } else {
                this.errorFile = 0;
                this.allFieldsValid = true;
            }
            this.form.files = nd
        },
        openFeedback(nd) {
            if(nd) {
                this.form.page_related = window.location.href

                const screenshotTarget = document.body;
                html2canvas(screenshotTarget).then((canvas) => {
                    console.log(canvas)
                    const base64image = canvas.toDataURL("image/png");

                    this.base64URL = base64image
                    this.form.base64File = base64image.replace(/^data:image\/(png|jpg);base64,/, "")
                });
            }
        }
    },
    methods: {
        ...globalMethods,
        close() {
            this.$emit('closeDialog')

            this.form = {
                type: '',
                subject: '',
                comment: '',
                page_related: '',
                files: [],
                base64File: ''
            }
            this.valid = false
            this.errorFile = false
            this.errorKeys = []
            this.filelist = []
            this.filelistInURL = []
            this.allFieldsValid = false
        },
        uploadAgain() {
            jquery(document).find(".trigger-field").trigger("click");
        },
        dragover(event) {
            event.preventDefault();
            if (!event.currentTarget.classList.contains("bg-green-300")) {
                event.currentTarget.classList.remove("bg-gray-100");
                event.currentTarget.classList.add("bg-green-300");
            }
        },
        dragleave(event) {
            event.currentTarget.classList.add("bg-gray-100");
            event.currentTarget.classList.remove("bg-green-300");
        },
        dropFile(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange();
        },
        onChange() {
            this.filelist.push(...this.$refs.file.files);
            let table = this.filelist;
            let errorCount = 0;
            Object.keys(table).forEach((key) => {
                if (table[key].type.split('/')[0] !== "application" || table[key].type.split('/')[0] !== "video") {
                    this.filelistInURL.push(this.createURL(table[key]))
                    errorCount++;
                }
            });
            if (errorCount > 0) {
                this.errorFile = 1;
                this.allFieldsValid = false;
            } else {
                this.errorFile = 0;
                this.allFieldsValid = true;
            }
        },
        createURL(input) {
            let file = input
            return URL.createObjectURL(file)
        },
        readUrl(input) {
            let file = input
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                };
                reader.onerror = error => reject(error);
            });
        },
        removeFile(i) {
            this.filelist.splice(i, 1)
            this.filelistInURL.splice(i, 1)
        },
        handleSubmit() {
            this.errorKeys = []
            Object.entries(this.form).forEach(([key, value]) => {
                if(key !== 'files') {
                    if(value === null || value === '' || value.length < 1) {
                        this.errorKeys.push(key)
                        this.$refs.formFeedback.validate()
                    }
                }
            })
            console.log(this.errorKeys)
            if(this.errorKeys.length < 1) {
                this.isFetching = true
                let formdata = new FormData()
                formdata.append('type', this.form.type)
                formdata.append('subject', this.form.subject)
                formdata.append('comment', this.form.comment)
                formdata.append('pagerelated', this.form.page_related)
                this.form.files.map((file, key) => {
                    formdata.append(`files[${key}]`, file)
                })
                formdata.append('base64', this.form.base64File)

                axios.post(`${APIBaseUrl}/share-feedback`, formdata)
                    .then(response => {
                        console.log(response)
                        this.form = {
                            type: '',
                            subject: '',
                            comment: '',
                            page_related: '',
                            files: [],
                            base64File: ''
                        }
                        this.valid = false
                        this.isFetching = false
                        this.errorFile = false
                        this.errorKeys = []
                        this.filelist = []
                        this.filelistInURL = []
                        this.allFieldsValid = false
                        this.$emit('closeDialogOnSuccess')
                    })
                    .catch(e => {
                        console.log(e)
                        this.dispatchNotifcationError(e.message)
                        this.isFetching = false
                    })
            }
        }
    }
}
</script>

<style>
@import '../../assets/css/dialog_styles/deleteDialog.css';
.textarea-field {
    flex-direction: column;
    align-items: flex-start;
    height: 76px;
    background: #FFFFFF;
    border: 1px solid #B4CFE0;
    border-radius: 4px;
    font-size: 14px;
    padding: 10px 17px;
    color: #4A4A4A;
    caret-color: #0171A1 !important;
}
.textarea-field:focus {
    outline: 1px solid #B4CFE0;
}
.rp-bg {
    background: #EBF2F5;
    height: 38px;
}
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 18px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc;
}
.control input:checked ~ .control__indicator {
  background: #2aa1c0;
}
.radio-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4A4A4A;
}
.deliveryOrderColumn {
    background-color: #fff;
    padding: 0px;
    width: 100%;
    margin-left: 8px;
    margin-right: 0;
    margin-bottom: 15px;
}
.pr-18 {
    padding-right: 93px !important;
}
.upload-btn {
    border: 1px solid #b4cfe0;
    border-radius: 5px;
    text-align: center;
    padding: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #0171A1;
}
.browser-btn {
    width: 100%;
    text-align: right;
    font-size: 12px;
}
.browser-text {
    color: #b4cfe0 !important;
    font-size: 14px;
    width: fit-content;
}
.drop-field {
    width: 151px;
    align-items: center;
    border: 1px solid #b4cfe0;
    padding: 20px 20px;
    border-style: dashed;
}
.img-ratio {
    width: 151px;
    height: 122px;
    object-fit: cover;
}
button.remove-ss-btn {
    position: absolute;
    margin: 89px -151px;
    background-color: #555 !important;
    color: white;
    font-size: 14px;
    padding: 6px 49.5px;
    border: none;
    cursor: pointer;
    text-align: center;
    z-index: 40;
    opacity: 0.8 !important;
}

button.remove-ss-btn:hover {
  background-color: black !important;
}
</style>