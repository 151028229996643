import axios from "axios";

const state = {
  yards: [],
  chassisTypes: [],
  pageCount: null,
  currentPage: null,
  ChassisLoading: false,
  createYard: null,
  updateYard: null,
  createChassisLoading: false,
  updateChassisLoading: false,
  deleteChassisLoading: false,
  yardSaveAddLoading: false,
  trucker_id: null,
  alert_box: false,
  alert_box_text: "",
  alert_box_type: "success",
  details: [],
};
const getters = {
  getList: (state) => state.yards,
  getChassisType: (state) => state.chassisTypes,
  getpageCount: (state) => state.pageCount,
  getCurrentPage: (state) => state.currentPage,
  getChassisLoading: (state) => state.ChassisLoading,
  getCreateYards: (state) => state.createYard,
  getUpdateYards: (state) => state.updateYard,
  getCreateChassisLoading: (state) => state.createChassisLoading,
  getUpdateChassisLoading: (state) => state.updateChassisLoading,
  getDeleteChassisLoading: (state) => state.deleteChassisLoading,
  getYardsSaveAddLoading: (state) => state.YardSaveAddLoading,
  getTruckerId: (state) => state.trucker_id,
  getalert_box: (state) => state.alert_box,
  get_alert_box_text: (state) => state.alert_box_text,
  get_alert_type: (state) => state.alert_box_type,
  getdetails: (state) => state.details,
};

const APIBaseUrl = process.env.VUE_APP_PO_URL;
const actions = {
  changeAlertBoxStatus: ({ commit }, payload) => {
    commit("CHANGE_STATUS_OF_ALERT", payload);
  },
  getChassisFromApi: async ({ commit }) => {
    commit("SET_YARDS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getChassis`)
      .then((res) => {  
        commit("SET_YARDS_LOADING", false);
        commit("SET_YARDS", res.data);
        //commit("SET_CHASSIS_TYPE", res.data.chassis_type);
      })
      .catch((err) => {
        //commit("SET_ALERT_BOX_TEXT", err);
        if (typeof err.error !== 'undefined') {
          return Promise.reject(err.error)
        } else {
          return Promise.reject(err.message)
        }
      });
  },
  getOthesPageYards: async ({ commit }, payload) => {
    commit("SET_YARDS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getChassis?page=${payload}`)
      .then((res) => {
        commit("SET_YARDS_LOADING", false);
        commit("SET_YARDS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  addNewChassis: async ({ commit }, payload) => {
    commit("SET_CREATE_CHASSIS_LOADING", true);
    const newYardObj = {
      number: payload.number,
      chassis_type_id: payload.chassis_type_id,
      trucker_id: state.trucker_id,
      trucker_owned: payload.trucker_owned,
    };
    await axios
      .post(`${APIBaseUrl}/createChassis`, newYardObj)
      .then((res) => {
        commit("SET_CREATE_CHASSIS_LOADING", false);
        commit("ADD_NEW_CHASSIS", res.data);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
  editChassisItem: async ({ commit }, payload) => {
    commit("SET_UPDATE_YARD_LOADING", true);
    let editChassisItems = {
      id: payload.id,
      number: payload.number,
      chassis_type_id: payload.chassis_type_id,
      type: payload.type,
      trucker_owned: payload.trucker_owned,
    };
      
    await axios
      .put(`${APIBaseUrl}/updateChassis/${payload.id}`, editChassisItems)
      .then((res) => {

        const currentState = state.yards;        
        const newEditArray = currentState.filter((item) => {
           return item.id !== payload.id;
        });   

        newEditArray.unshift(editChassisItems); 
        console.log(newEditArray)

        commit("SET_UPDATE_YARDS",{
          newEditArray: newEditArray,
          data: res.data,
          olditem: currentState
        });

        commit("SET_UPDATE_YARD_LOADING", false);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
  deleteChassisItem: async ({ commit }, payload) => {
    commit("SET_DELETE_YARD_LOADING", true);
    await axios
      .delete(`${APIBaseUrl}/deleteChassis/${payload.id}`)
      .then((res) => {
        commit("REMOVE_YARD_ITEM", res.data);
        commit("SET_DELETE_YARD_LOADING", false);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
};

const mutations = {
  CHANGE_STATUS_OF_ALERT: (state, payload) => {
    state.alert_box = payload;
  },
  SET_ALERT_BOX_TEXT: (state, payload) => {
    state.ChassisLoading = false;
    state.alert_box_type = "error";
    state.alert_box_text = payload;
    state.alert_box = true;
  },
  REMOVE_YARD_ITEM: (state, payload) => {

    state.yards = [];   
    state.yards = payload.yards.data;

    //state.pageCount = payload.yards.last_page;
    // state.current_page = payload.yards.current_page;

    state.trucker_id = payload.trucker_id;
    state.updateChassisLoading = false;
    state.alert_box = true;
    state.alert_box_text = "Chassis has been deleted";
    state.alert_box_type = "delete";
  },
  ADD_NEW_CHASSIS: (state, payload) => {
    state.yards = [];
    state.yards = payload.yards.data;
    state.pageCount = payload.yards.last_page;
    state.current_page = payload.yards.current_page;
    state.trucker_id = payload.trucker_id;
    state.alert_box = true;
    state.alert_box_text = "Chassis has been created";
    state.alert_box_type = "success";
    state.details = {status:payload.status,msg:payload.msg};   
  },
  SET_YARDS: (state, payload) => {
    state.yards = payload.yards.data;
    state.pageCount = payload.yards.last_page;
    state.current_page = payload.yards.current_page;
    state.trucker_id = payload.trucker_id;
    state.chassisTypes = payload.yards.chassis_type
  }, 
  SET_YARDS_LOADING: (state, payload) => {
    state.ChassisLoading = payload;
  },
  SET_CREATE_YARDS: (state, payload) => {
    state.createYard = payload;
  },
  SET_CREATE_CHASSIS_LOADING: (state, payload) => {
    state.createChassisLoading = payload;
  },
  SET_YARDS_SAVE_ADD_LOADING: (state, payload) => {
    state.yardSaveAddLoading = payload;
  },
  SET_UPDATE_YARDS: (state, payload) => {
    state.yards = [];
    state.alert_box = true;
    state.alert_box_text = "Chassis has been updated";
    state.alert_box_type = "success";   
    state.yards = payload.data.status?payload.newEditArray:payload.olditem;
    state.details = {
                      status:payload.data.status,
                      msg:payload.data.message
                    }; 
  },
  SET_UPDATE_YARD_LOADING: (state, payload) => {
    state.updateChassisLoading = payload;
  },
  SET_DELETE_YARD_LOADING: (state,payload) => {
    state.deleteChassisLoading = payload;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
