import iziToast from "izitoast"

const globalMethods = {
	notificationMessage: (message) => {
        iziToast.success({
            class: 'categories-toast',
            title: '',
            message: `${message}`,
            displayMode: 1,
            position: 'topRight',
            timeout: 3000,
        });
    },
    emailsendToVendor: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#16B442',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3500,            
            progressBar: false, 
            close: false
        });
    },
    dispatchNotifcationError: (error) => {
        iziToast.error({
            class: 'categories-toast',         
            message: error,           
            position: 'bottomCenter',
            timeout: 3000,
            progressBar: false, 
            close: false
        });
    },
    dispatchNotifcation: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3000,            
            progressBar: false, 
            close: false
        });
    },
    addContainerNotification: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#EBFAEF',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 5000,            
            progressBar: false, 
            close: false
        });
    },
    deliveryNotification: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 10000,            
            progressBar: false, 
            close: false
        });
    },
    showDeleteNotificationBox: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 5000,            
            progressBar: false, 
            close: false
        });
    },
    showPreparingdownload: (message) => {
        iziToast.show({
            id: null, 
            class: 'izitoast_box',           
            message: `${message}`,           
            backgroundColor: '#4A4A4A',         
            position: 'bottomCenter', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
            timeout: 3500,            
            progressBar: false, 
            close: false
        });
    },
    notificationError: (error) => {
        iziToast.error({
            class: 'categories-toast',
            title: 'Error',
            message: error,
            displayMode: 1,
            position: 'topRight',
            timeout: 3000,
        });
    },
    notificationErrorCustom: (error) => {
        iziToast.info({
            class: 'product-toast',
            // title: 'Error',
            message: error,
            displayMode: 1,
            position: 'bottomCenter',
            timeout: 3000,
        });
    },
    notificationCustom: (message) => {
        iziToast.info({
            class: "product-toast",
            // title: 'Error',
            message: message,
            displayMode: 1,
            position: "bottomCenter",
            timeout: 3000,
        });
    },
    notificationMessageCustomSuccess(message) {
        iziToast.success({
            class: "izitoast-message-custom",
            theme: "dark",
            message: message,
            backgroundColor: "#16B442",
            messageColor: "#ffffff",
            iconColor: "#ffffff",
            progressBarColor: "#ADEEBD",
            displayMode: 1,
            position: "bottomCenter",
            timeout: 3000,
        });
    },
    notificationCustomAlert: (data) => {
        iziToast.info({
            class: `product-toast ${data.class}`,
            // title: 'Error',
            message: data.message,
            displayMode: 1,
            position: "bottomCenter",
            timeout: data.timeout,
        });
    },
}

export default globalMethods