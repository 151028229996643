<template>
  <div class="sidebar">
    <div class="logo">
      <router-link to="/shipments" class="shipment-logo">
        <img src="@/assets/images/logo.png" alt="" />
      </router-link>
    </div>

    <ul class="links sidebarMenu">
      <li>
        <router-link
          to="/containers"
          v-bind:class="{
            'active-link':
              $store.state.page.currentPage == 'containers' ||
              $store.state.page.currentPage == 'delivery-order',
          }"
        >
          <i class="ic-truckings"></i>
          Containers
        </router-link>
      </li>
      <li>
        <router-link
          to="/Dos"
          v-bind:class="{
            'active-link':
              $store.state.page.currentPage == 'Dos' ||
              $store.state.page.currentPage == 'Dos',
          }"
        >
          <i class="ic-truckings"></i>
          Dos
        </router-link>
      </li>
      <li>
        <router-link
          to="/drivers/01"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'drivers',
          }"
        >
          <i class="ic-drivers"></i>
          Drivers
        </router-link>
      </li>
      <li>
        <router-link
          to="/yards"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'yards',
          }"
        >
          <i class="ic-yards"></i>
          Yards
        </router-link>
      </li>
      <li>
        <router-link
          to="/customers"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'customers',
          }"
        >
          <i class="ic-suppliers"></i>
          Customer
        </router-link>
      </li>
      <li>
        <router-link
          to="/chassis"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'chassis',
          }"
        >
          <i class="ic-chassis"></i>
          Chassis
        </router-link>
      </li>
      <li>
        <router-link
          to="/reports"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'reports',
          }"
        >
          <i class="ic-reports mr-3"></i>
          Reports
        </router-link>
      </li>

      <li>
        <router-link
          to="/map"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'map',
          }"
        >
          <i class="ic-map-pin"></i>
          Map
        </router-link>
      </li>

      <v-expansion-panels v-if="accountingModuleEnabled" accordion flat v-model="accountingMenuShow">
				<v-expansion-panel>
					<v-expansion-panel-header>
						<div class="settings-menu-group">
							<img src="@/assets/images/transfer.svg" alt="" />
							Accounting
						</div>
					</v-expansion-panel-header>

					<v-expansion-panel-content>
						<v-list-item-group two-line class="pa-0">
							<template v-for="(item, i) in accountingMenuItems">
								<v-list-item
									v-if="item.nonQBO ? isQBOEnabled === 0 : true"
									:key="`accounting-menu-${i}`"
									:class="{ 'active-link': getCurrentPageIndex === i + 1 && $route.path === item.path }"
									@click.native="onClickAccounting(i + 1)"
								>
									<v-list-item-title>
										<router-link :to="item.path">
											<div class="icon-separator"></div>
											<span>{{ item.title }}</span>
										</router-link>
									</v-list-item-title>
								</v-list-item>
							</template>
						</v-list-item-group>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

      <!-- <li>
        <div
          style="margin-left: -8px"
          class="settings-popup-menu settings_bottom_menu"
          v-bind:class="{
            'active-link': $store.state.page.currentPage.includes('settings'),
          }"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            content-class="drawer-menu-popover-new elevation-0"
          > -->
      <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="settings-btn"
                :ripple="false"
                dark
                v-bind="attrs"
                v-on="on"
              >
                <router-link
                  to="settings"
                  v-bind:class="{
                    'active-link': $store.state.page.currentPage == 'settings',
                  }"
                > -->
      <!-- to="/settings"
                  v-bind:class="{
                    'active-link': $store.state.page.currentPage == 'settings',
                  }" -->
      <!-- <div class="settings-menu-group">
                    <img src="@/assets/images/settings.svg" alt="" />
                    Settings
                  </div>

                  <img
                    v-if="menu"
                    class="img-left"
                    src="@/assets/icons/arrow_up_white.svg"
                    alt=""
                  />
                  <img
                    v-else
                    class="img-left"
                    src="@/assets/icons/arrow_down_field_white.svg"
                    alt=""
                  />
                </div>
              </v-btn>
            </template> -->
      <!-- <v-card> -->
      <!-- <v-list
                class="custom-color"
                style="
                  background-color: #fff;
                  border-radius: 0 !important;
                  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0);
                "
              > -->
      <!-- style="background-color: #0271a1; border-radius: 0px" -->

      <!-- <v-list-item style="background-color: #015d85">
                  <v-list-item-title>
                    <router-link
                      to="/settings/company-profile"
                      v-bind:class="{
                        'active-link':
                          $store.state.page.currentPage ==
                          'settings/company-profile',
                      }"
                    > -->
      <!-- style="color: #fff"
                      to="/settings/?tab=users"
                      v-bind:class="{
                        'active-link':
                          $store.state.page.currentSettingsTab == 0,
                      }" -->
      <!-- Company Profile
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list> -->

      <!-- </v-card> -->
      <!-- </v-menu>
        </div>
      </li> -->
      <v-expansion-panels accordion flat v-model="settingsMenuShow">
        <v-expansion-panel v-model="settingsMenuShow">
          <v-expansion-panel-header>
            <div class="settings-menu-group">
              <img src="@/assets/images/settings.svg" alt="" />
              Settings
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <v-list-item-group two-line class="pa-0">
              <v-list-item
                @click.native="onClickSettings(0)"
                :class="
                  $router.currentRoute.name === 'Settings' &&
                  $store.state.page.currentSettingsTab == 0
                    ? 'active-link'
                    : ''
                "
              >
                <v-list-item-title>
                  <router-link to="/settings?tab=company-profile">
                    <div class="icon-separator"></div>
                    <span>Company Profile</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="onClickSettings(1)"
                :class="
                  $router.currentRoute.name === 'Settings' &&
                  $store.state.page.currentSettingsTab == 1
                    ? 'active-link'
                    : ''
                "
              >
                <v-list-item-title>
                  <router-link to="/settings?tab=notifications">
                    <div class="icon-separator"></div>
                    Notifications
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="onClickSettings(2)"
                :class="
                  $router.currentRoute.name === 'Settings' &&
                  $store.state.page.currentSettingsTab == 2
                    ? 'active-link'
                    : ''
                "
              >
                <v-list-item-title>
                  <router-link to="/settings?tab=integrations">
                    <div class="icon-separator"></div>
                    Integrations
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <li>
        <router-link
          to="/map"
          v-bind:class="{
            'active-link': $store.state.page.currentPage == 'map',
          }"
        >
          <i class="ic-map-pin"></i>
          Map
        </router-link>
      </li> -->

      <!-- Settings Pop Over -->
      <!-- <div
        class="settings-popup-menu settings_bottom_menu"
        v-bind:class="{
          'active-link': $store.state.page.currentPage.includes('settings'),
        }"
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          content-class="drawer-menu-popover"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="settings-btn"
              :ripple="false"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <router-link
                to="/settings"
                v-bind:class="{
                  'active-link': $store.state.page.currentPage == 'settings',
                }"
              >
                <div class="settings-menu-group">
                  <img src="@/assets/images/settings.svg" alt="" />
                  Settings
                </div>

                <img src="@/assets/images/chevron-right.svg" alt="" />
              </router-link>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <router-link
                    to="/settings/?tab=users"
                    v-bind:class="{
                      'active-link': $store.state.page.currentSettingsTab == 0,
                    }"
                    @click.native="onClickSettings(0)"
                  >
                    <img
                      src="@/assets/images/users.svg"
                      width="22px"
                      height="22px"
                    />
                    Users
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <router-link
                    to="/settings/?tab=notifications"
                    v-bind:class="{
                      'active-link': $store.state.page.currentSettingsTab == 1,
                    }"
                    @click.native="onClickSettings(1)"
                  >
                    <img
                      src="@/assets/images/notification.svg"
                      width="22px"
                      height="22px"
                    />
                    Notifications
                  </router-link>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title>
                  <router-link
                    to="/settings/?tab=manage-payment-methods"
                    v-bind:class="{
                      'active-link': $store.state.page.currentSettingsTab == 2,
                    }"
                    @click.native="onClickSettings(2)"
                  >
                    <img
                      src="@/assets/images/payment.svg"
                      width="22px"
                      height="22px"
                    />
                    Payment Methods
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div> -->

      <div
        v-if="isMobile"
        style="height: 2px; background-color: #039ddc; margin: 12px"
      ></div>

      <!-- REDIRECT TO PROFILE PAGE -->
      <!-- <li v-if="isMobile">
                <router-link to="/profile" v-bind:class="{'active-link': ($store.state.page.currentPage=='profile')}">
                    <v-avatar size="35" style="margin-right: 10px">
                        <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                        <v-icon small class="mr-2 account" > mdi-account </v-icon>
                    </v-avatar>
                    {{ getUser ? getUser.success.name : "" }}
                </router-link>
            </li> -->

      <!-- TEMPORARY NO REDIRECTION -->
      <li
        v-if="isMobile"
        style="
          padding: 0 16px;
          color: #e1ecf0 !important;
          display: flex;
          align-items: center;
          cursor: pointer;
        "
      >
        <v-avatar size="35" style="margin-right: 10px">
          <v-icon small class="mr-2 account"> mdi-account </v-icon>
        </v-avatar>
        {{ getUser ? JSON.parse(getUser).name : "" }}
      </li>
      <li v-if="isMobile">
        <v-btn @click="userLogout" class="btn-logout-mobile">
          <img src="@/assets/images/sign-out.png" alt="" />
          Sign Out
        </v-btn>
      </li>
    </ul>

    <p class="footer">© {{ getYear() }} Shifl. All rights reserved.</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { accountingMenuItems } from '../router/accounting';

export default {
  name: "DrawerMenu",
  props: ["isMobile"],
  data: () => ({
    menu: false,
    settingsMenuShow: -1,
    accountingMenuShow: -1,
  }),
  methods: {
    //
    ...mapActions(["logout"]),
    userLogout() {
      this.$store.dispatch("page/setTab", 1);
      this.$store.dispatch("page/setCurrentSettingsTab", 0);
      this.logout();
    },
    onClickSettings(index) {
      this.$store.dispatch("page/setCurrentSettingsTab", index);
      this.$store.dispatch("page/setAccessMenuitem",1);
    },
    getYear() {
      return moment().format("YYYY");
    },
    onClickAccounting(index) {
      if(this.$store.hasModule('accounting')){
				this.$store.dispatch("accounting/setAccountingPageIndex", index);
			}
		},
  },
  computed: {
    ...mapGetters(["getUser"]),

    accountingMenuItems() {
      return accountingMenuItems;
    },

		accountingModuleEnabled(){
			if(this.$store.hasModule('accounting')){
				return true;
			}
			return false;
		},

		isQBOEnabled(){
			if(this.$store.hasModule('accounting')){
				return this.$store.getters['accounting/isQBOEnabled'];
			}
			return 0;
		},

		getCurrentPageIndex(){
			if(this.$store.hasModule('accounting')){
				return this.$store.getters['accounting/getCurrentPageIndex'];
			}
			return 1;
		},

  },
  updated() {},
};
</script>

<style lang="scss">
@import "../assets/scss/components/drawer.scss";
@import "../assets/scss/components/drawer-1.scss";
.drawer-menu-popover-new {
  min-width: 216px !important;
  width: 216px;
  border-radius: 0;
  left: 0px !important;

  .v-list {
    height: 48px;
    padding: 0;
    .v-list-item {
      a {
        font-size: 14px;
        color: $white-to-blue !important;
        text-decoration: none;
        height: 48px !important;
        padding: 15px 20px;
        border-left: 2px solid $hover-blue;
      }
      &:hover {
        background-color: $hover-blue;

        // background-color: $dark-blue-active !important;
      }
    }
  }
}
.settingsDropDown {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  align-items: center;
}
.img-left {
  margin-left: 50%;
}
.dropDownTxt {
  margin-left: -10px;
}
.custom-color {
  background-color: $dark-blue-active !important;
}
</style>
