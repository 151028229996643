import mapboxgl from "mapbox-gl";
import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_PO_URL;

const state = {
  containers: [],
};
const getters = {
  getContainers: (state) => state.containers,
};
const actions = {
  fetchContainerLocationFromApi({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIBaseUrl}/get-containers-location`)
        .then((res) => {
          commit("SET_CONTAINERS", res.data);
          resolve("done");
        })
        .catch((e) => {
          reject("error occured", e);
        });
    });
  },

  drawContainerIcons(context) {
    if (context.rootGetters["map/getMapInstance"] !== null) {
      var markers = [
        {
          type: String,
          shipmentId: Number,
          marker: Object,
        },
      ];
      context.getters.getContainers.forEach((element) => {
        if (
          element.container_location.latitude !== null ||
          element.container_location.addressLatitude !== null
        ) {
          let el = document.createElement("img");
          el.src = require("@/assets/images/container.png");
          el.height = 48;
          el.width = 40;
          el.style.borderRadius = 25;
          var tempMarker = new mapboxgl.Marker(el)
            .setLngLat([
              element.container_location.longitude ||
                element.container_location.addressLongitude,
              element.container_location.latitude ||
                element.container_location.addressLatitude,
            ])
            .setPopup(
              new mapboxgl.Popup({
                className: "custom-popup",
                closeButton: false,
              }).setHTML(`
                  <div class="twoRowHeight">
                      <p class="headingRowPopup">SHIPMENT ID #${element.id} </p>
                      <p class="subRow">Location : ${element.container_location.address}</p>
                      </p>
                    </div>`)
            )
            .addTo(context.rootGetters["map/getMapInstance"]);
        }

        const item = {
          type: "containerMarker",
          shipmentId: element.container_location.id,
          marker: tempMarker,
        };

        markers.push(item);
      });
      context.commit("map/SET_PREV_CONTAINER_MARKERS", markers, { root: true });
    }
  },
};
const mutations = {
  SET_CONTAINERS: (state, payload) => {
    state.containers = payload.data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
