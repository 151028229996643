import axios from "axios"

const state = {
    currentProductSelected: null,
	products: [],
	productsLoading: false,
	createProducts: null,
	createProductsLoading: false,
	updateProduct: null,
	updateProductLoading: false,
	deleteProductLoading: false,
	error: '',
	poBaseUrlState: process.env.VUE_APP_PO_URL,
	reset: false,
	singleProduct: null,
	singleProductLoading: false,
	productsPoDropdown: []
}

const getters = {
	poBaseUrlState: state => state.poBaseUrlState,
    getCurrentSelectedProduct: state => state.currentProductSelected,
	getProducts: state => state.products,
	getProductsPoDropdown: state => state.productsPoDropdown,
	getProductsLoading: state => state.productsLoading,
	getCreateProducts: state => state.createProducts,
	getCreateProductsLoading: state => state.createProductsLoading,
	getUpdatedProduct: state => state.updateProduct,
	getUpdatedProductLoading: state => state.updateProductLoading,
	getDeleteProductLoading: state => state.deleteProductLoading,
	getError: state => state.error,
	getSingleProduct: state => state.singleProduct,
	getSingleProductLoading: state => state.singleProductLoading
}

// const poBaseUrl = "https://po.shifl.com/api"

const actions = {
    setProduct({ commit }, payload) {
        commit("setProduct", payload)
    },
    setReset({ commit }, payload) {
		commit("setReset", payload)
	},
	fetchProducts: async ({
		commit
	}, ) => {
		commit("SET_PRODUCTS_LOADING", true)
	
		await axios.get(`${process.env.VUE_APP_PO_URL}/products`)
		.then(res => {
			commit("SET_PRODUCTS_LOADING", false)
			if (res.status === 200) {
				if (typeof res.data!=='undefined') {
					// console.log(res.data.results);
					let productsData = []
					if (typeof res.data.results!=='undefined' && typeof res.data.results.data!=='undefined')
						productsData = res.data.results.data

					
					if(productsData.length > 0){
						const tempProductsData = productsData.map(product => {
							if(!product.carton_dimensions){
								return product
							}
							return {
								...product,
								carton_dimensions: {
									l: JSON.parse(product.carton_dimensions).l,
									w: JSON.parse(product.carton_dimensions).w,
									h: JSON.parse(product.carton_dimensions).h,
									uom: JSON.parse(product.carton_dimensions).uom
								}
							}
						})
						productsData = tempProductsData
					}


					commit("SET_PRODUCTS", productsData)

				} else {
					commit("SET_PRODUCTS", [])
				}
			}
		})
		.catch(err => {
			commit("SET_PRODUCTS_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				return Promise.reject(err.message)
			}
		})
	},
	createProducts: async ({
		commit
	}, payload ) => {
		commit("SET_CREATE_PRODUCTS_LOADING", true)

		let fd = new FormData()
		let getPayloadKeys = Object.keys(payload)

		if (getPayloadKeys.length > 0) {
			getPayloadKeys.map(gpk => {
				fd.append(gpk, payload[gpk])
			})
		}

		if (payload !== null) {
			// if image is not null, use form data
			if (payload.image !== null) {
				await axios.post(`${process.env.VUE_APP_PO_URL}/products/create`, fd, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					if (res.status === 200) {
						if (res.data) {
							// console.log(res.data);
							commit("SET_CREATE_PRODUCTS_LOADING", false)
							// commit("SET_CREATE_PRODUCTS", res.data)
						}
					}
				})
				.catch(err => {
					commit("SET_CREATE_PRODUCTS_LOADING", false)
					if (typeof err.error !== 'undefined') {
						return Promise.reject(err.error)
					} else {
						return Promise.reject(err.message)
					}
				})

			// if image is null, do not use form data
			} else {
				await axios.post(`${process.env.VUE_APP_PO_URL}/products/create`, payload)
				.then(res => {
					if (res.status === 200) {
						if (res.data) {
							// console.log(res.data);
							commit("SET_CREATE_PRODUCTS_LOADING", false)
							// commit("SET_CREATE_PRODUCTS", res.data)
						}
					}
				})
				.catch(err => {
					commit("SET_CREATE_PRODUCTS_LOADING", false)
					if (typeof err.error !== 'undefined') {
						return Promise.reject(err.error)
					} else {
						return Promise.reject(err.message)
					}
				})
			}
		} 	
	},
	updateProducts: async ({
		commit
	}, payload) => {
		commit("SET_UPDATE_PRODUCTS_LOADING", true)

		let fd = new FormData()
		let getPayloadKeys = Object.keys(payload)

		if (getPayloadKeys.length > 0) {
			getPayloadKeys.map(gpk => {
				fd.append(gpk, payload[gpk])
			})
		}

		if (payload !== null) {
			// if image is not null, use form data
			if (payload.image !== null) {
				await axios.post(`${process.env.VUE_APP_PO_URL}/products/update/${payload.prod_id}`, fd, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(res => {
					if (res.status === 200) {
						if (res.data) {
							// console.log(res.data);
							commit("SET_UPDATE_PRODUCTS_LOADING", false)
							// commit("SET_UPDATE_PRODUCTS", res.data)
						}
					}
				})
				.catch(err => {
					commit("SET_UPDATE_PRODUCTS_LOADING", false)
					if (typeof err.error !== 'undefined') {
						return Promise.reject(err.error)
					} else {
						return Promise.reject(err.message)
					}
				})

			// if image is null, do not use form data
			} else {
				await axios.post(`${process.env.VUE_APP_PO_URL}/products/update/${payload.prod_id}`, payload)
				.then(res => {
					if (res.status === 200) {
						if (res.data) {
							// console.log(res.data);
							commit("SET_UPDATE_PRODUCTS_LOADING", false)
							// commit("SET_UPDATE_PRODUCTS", res.data)
						}
					}
				})
				.catch(err => {
					commit("SET_UPDATE_PRODUCTS_LOADING", false)
					if (typeof err.error !== 'undefined') {
						return Promise.reject(err.error)
					} else {
						return Promise.reject(err.message)
					}
				})
			}
		}
	},
	deleteProduct: async({
		commit
	}, id) => {
		commit("SET_DELETE_PRODUCT_LOADING", true)

		await axios.delete(`${process.env.VUE_APP_PO_URL}/products/delete/${id}`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					// console.log(res.data);
					commit("SET_DELETE_PRODUCT_LOADING", false)
					// commit("SET_DELETE_CATEGORIES", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_DELETE_PRODUCT_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				return Promise.reject(err.message)
			}
		})
	},
	fetchSingleProduct: async ({
		commit,
	}, id) => {
		commit("SET_SINGLE_PRODUCT_LOADING", true)

		await axios.get(`${process.env.VUE_APP_PO_URL}/products/${id}`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					// console.log(res.data.results);
					commit("SET_SINGLE_PRODUCT_LOADING", false)
					commit("SET_SINGLE_PRODUCT", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_SINGLE_PRODUCT_LOADING", false)
			if (typeof err.error !== 'undefined') {
				return Promise.reject(err.error)
			} else {
				return Promise.reject(err.message)
			}
		})
	}
}

const methods = {
	testing: () => {
		alert('testing')
	}
}

const mutations = {
	setReset: (state, payload) => {
		state.reset = payload
	},
    setProduct: (state, payload) => {
        state.currentProductSelected = payload
    },
	SET_PRODUCTS: (state, payload) => {
		state.products = payload
	},
	SET_PRODUCTS_LOADING: (state, payload) => {
		state.productsLoading = payload
	},
	SET_CREATE_PRODUCTS: (state, payload) => {
		state.createProducts = payload
	},
	SET_CREATE_PRODUCTS_LOADING: (state, payload) => {
		state.createProductsLoading = payload
	},
	SET_UPDATE_PRODUCTS: (state, payload) => {
		state.updateProduct = payload
	},
	SET_UPDATE_PRODUCTS_LOADING: (state, payload) => {
		state.updateProductLoading = payload
	},
	SET_DELETE_PRODUCT_LOADING: (state, payload) => {
		state.deleteProductLoading = payload
	},
	SET_SINGLE_PRODUCT: (state, payload) => {
		state.singleProduct = payload
	},
	SET_SINGLE_PRODUCT_LOADING: (state, payload) => {
		state.singleProductLoading = payload
	},
	SET_ERROR: (state, payload) => {
		state.error = payload
	}
}

export default {
    namespaced: true,
    state,
	getters,
	actions,
	mutations,
	methods
}
