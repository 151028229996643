import axios from "axios";
const poBaseUrl = process.env.VUE_APP_PO_URL;

const state = {
  terminals: [],
  ports: [],
  drivers: [],
  noFilterDrivers: [],
  chassis: [],
  yards: [],
  yardsFetching: false,
};

const getters = {
  getTerminals: (state) => state.terminals,
  getPorts: (state) => state.ports,
  getDrivers: (state) => state.drivers,
  getChassis: (state) => state.chassis,
  getYards: (state) => state.yards,
  getNoFilterDrivers: (state) => state.noFilterDrivers
};

const actions = {
  fetchDrivers: async ({ commit }) => {
    await axios
      .get(`${poBaseUrl}/getdrivers`)
      .then((response) => {
        let driver = [];
        driver.push({
          id: 0,
          name: "Unassigned",
        });
        response["data"]["drivers"].forEach((value) => {
          driver.push({
            id: [value][0].id,
            name: [value][0].name,
          });
        });
        commit("SET_DRIVERS", driver);
        commit("SET_NO_FILTER_DRIVERS", response.data.drivers);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  fetchChassis: async ({ commit }) => {
    await axios
      .get(`${poBaseUrl}/getcontainers-chassis`)
      .then((response) => {
        let chassis = [];
        response.data.chassis.map((value) => {
          chassis.push({
            id: value.id,
            name: value.number,
          });
        });
        commit("SET_CHASSIS", chassis);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  fetchTerminals: async ({ commit }) => {
    await axios
      .get(`${poBaseUrl}/terminals`)
      .then((response) => {
        commit("SET_PORTS", response.data);
        let locationdropdown = [];
        if (typeof response["data"] != "undefined") {
          response["data"].forEach((value) => {
            locationdropdown.push({
              id: value.id,
              name: value.name,              
              nickname: value.nickname,
              address: value.address,
              firms_code: value.firms_code
            });
          });
          commit("SET_TERMINALS", locationdropdown);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  fetchYards: async ({ commit, state}) => {
    if(!state.yardsFetching){
      commit("SET_YARDS_FETCHING", true)
      await axios
        .get(`${poBaseUrl}/getyards`)
        .then((response) => {
          commit("SET_YARDS", response.data.yards.data !== undefined ? response.data.yards.data : [])
        })
        .catch((err) => {
          console.log(err);
        });
      commit("SET_YARDS_FETCHING", false)

    }

  },
};

const mutations = {
  SET_DRIVERS: (state, payload) => {
    state.drivers = payload;
  },
  SET_NO_FILTER_DRIVERS: (state, payload) => {
    state.noFilterDrivers = payload;
  },
  SET_CHASSIS: (state, payload) => {
    state.chassis = payload;
  },
  SET_TERMINALS: (state, payload) => {
    state.terminals = payload;
  },
  SET_PORTS: (state, payload) => {
    state.ports = payload;
  },
  SET_YARDS: (state, payload) => {
    state.yards = payload;
  },
  SET_YARDS_FETCHING: (state, payload) => {
    state.yardsFetching = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
