import axios from "axios";
// // import router from '../../router/index'
// import Shipment from './../../custom/ShipmentResource'

const state = {
  allcontainers: [],
  statusLoading: false,
  poLoading: false,
  tabloading: false,
  containersFormDetails: [],
  pendingcontainers: [],
  availablecontainers: [],
  yardfullcontainers: [],
  assignedcontainers: [],
  droppedfullcontainers: [],
  droppedemptycontainers: [],
  pendingbillingcontainers: [],
  completed: [],
  tabcounter: [],
  allcontainersStatuses: [],
  fetchContainerdetails: [],
  editDetails: [],
  editViewData: [],
  currentTabSelected: [],
  scheduleFilterState: [],
  fetchcarriers: [],
  consigneeLists: [],
  consigneeLoading: false,
  createConsigneeLoading: false,
  updateIndexesLoading: false,
  allConsigneeLists: []
};

const getters = {
  // came from tabledesktop
  getEditviewData: (state) => state.editViewData,
  getstatusLoading: (state) => state.statusLoading,
  getEditDetails: (state) => state.editDetails,
  getAllContainers: (state) => state.allcontainers,
  getPoLoading: (state) => state.poLoading,
  getTabLoading: (state) => state.tabloading,
  getcontainerFormDetails: (state) => state.containersFormDetails,
  getPending: (state) => state.pendingcontainers,
  getAvailable: (state) => state.availablecontainers,
  getyardfull: (state) => state.yardfullcontainers,
  getassigned: (state) => state.assignedcontainers,
  getdroppedfull: (state) => state.droppedfullcontainers,
  getdroppedempty: (state) => state.droppedemptycontainers,
  getpendingbilling: (state) => state.pendingbillingcontainers,
  getcompleted: (state) => state.completed,
  gettabcounter: (state) => state.tabcounter,
  getAllcontainersStatus: (state) => state.allcontainersStatuses,
  getContainerdetails: (state) =>
    state.fetchContainerdetails /** single container */,
  getCurrentSelectedTab: (state) => state.currentTabSelected,
  getScheduledFilterState: (state) => state.scheduleFilterState,
  getAllCarriers: (state) => state.fetchcarriers,
  getAllConsignees: state => state.consigneeLists,
  getAllConsigneesLoading: state => state.consigneeLoading,
  getCreateConsigneeLoading: state => state.createConsigneeLoading,
  getUpdateIndexesLoading: state => state.updateIndexesLoading,
  getAllConsigneesLists: state => state.allConsigneeLists
};

const poBaseUrl = process.env.VUE_APP_PO_URL;
//const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL
const actions = {
  fetchCarriers: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/newcarriers`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("FETCH_ALL_CARRIERS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  setScheduleFilterState: async ({ commit }, payload) => {
    commit("CURRENT_SCHEDULE_FILTER_STATE", payload);
  },
  currentTabContainer: async ({ commit }, payload) => {
    commit("CURRENT_TAB_SELECTED", payload);
  },
  passData: async ({ commit }, payload) => {
    commit("EDITVIEWDATA", payload);
  },
  fetchDetails: async ({ commit }, payload) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/getcontainer/${payload}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("FETCH_ALL_CONTAINERS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  allcontainerstatus: async ({ commit }, url = `${poBaseUrl}/allcontainer/status`) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            console.log('123')
            try {
              let full = res.data.data.at_the_customer.data.filter(
                (s) => !s.container_empty
              );
              let empty = res.data.data.at_the_customer.data.filter(
                (s) => s.container_empty
              );
              res.data.data.at_the_customer.full = {
                data: full,
                count: full.length,
              };
              res.data.data.at_the_customer.empty = {
                data: empty,
                count: empty.length,
              };

              //

              full = res.data.data.at_yard.data.filter(
                (s) => !s.container_empty
              );
              empty = res.data.data.at_yard.data.filter(
                (s) => s.container_empty
              );
              res.data.data.at_yard.full = { data: full, count: full.length };
              res.data.data.at_yard.empty = {
                data: empty,
                count: empty.length,
              };
            } catch (e) {
              console.log(e);
            }
            commit("ALL_CONTAINER_STATUSES", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  alltabCounter: async ({ commit }) => {
    commit("SET_TAB_LOADING", true);
    await axios
      .get(`${poBaseUrl}/alltabCounter`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_TAB_LOADING", false);
            commit("SET_CON_ALLTABCOUNTER", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  // pending
  fetchpending: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/pending_availibility`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_PENDING", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  //Available
  fetchAvailable: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/available`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_AVAILABLE", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  //Available
  yardfull: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/in_yard_full`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_YARDFULL", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  //assigned containers
  assigned: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/assigned`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_ASSIGNEDCONTAINERS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  //dropped full containers
  droppedfull: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/dropped_full`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_DROPFULLCONTAINERS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  // droppempty
  droppedEmpty: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/dropped_empty`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CON_DROPEMPTY", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  // pending billing
  pendingBilling: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/pending_billing`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_PENDINGBILLING", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  completed: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers/completed`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_COMPLETED", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  fetchallformdetails: async ({ commit }) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/containers-details`)
      // let itemid = this.$route.params.id;
      // await axios.get(`${poBaseUrl}/getcontainer/${payload}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_FORM_DETAILS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },

  /**
   *  Fetch edit
   */
  fetchEdit: async ({ commit }, payload) => {
    commit("SET_PO_LOADING", true);
    await axios
      .get(`${poBaseUrl}/getcontainer/${payload}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_EDIT_DETAILS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },

  fetchContainers: async ({ commit }) => {
    commit("SET_PO_LOADING", true);

    await axios
      .get(`${poBaseUrl}/containers/pending_availibility`)
      //await axios.get(`${poBaseUrl}/getcontainers`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            commit("SET_CONTAINERS", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },

  setContainerEmptyStatus: async ({ commit }, payload) => {
    commit("SET_PO_LOADING", true);
    await axios
      .post(`${poBaseUrl}/container-empty/` + payload.item.id, payload.dateTime)
      .then((res) => {
        commit("SET_PO_LOADING", false);
        if (res.status === 200) {
          if (res.data) {
            commit("SET_PO_LOADING", false);
            // this.actions.allcontainerstatus()
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_PO_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  fetchConsignees: async ({ commit }, id) => {
    commit("SET_CONSIGNEES_LOADING", true);
    commit("SET_CONSIGNEES", []);

    await axios
      .get(`${poBaseUrl}/get-consignees/${id}`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_CONSIGNEES_LOADING", false);
            commit("SET_CONSIGNEES", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_CONSIGNEES_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  createConsignee: async ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			// let attempt = false

			function proceed() {
				commit("SET_CREATE_CONSIGNEES_LOADING", true)

				axios.post(`${poBaseUrl}/add-consignee`, payload)
					.then(res => {
						commit("SET_CREATE_CONSIGNEES_LOADING", false)
						if (typeof res!=='undefined' && res.status === 200) {
							resolve(res.data)
						}
					})
					.catch(err => {
						commit('SET_CREATE_CONSIGNEES_LOADING', false)

						if (typeof err.message!=='undefined') {
							// if (!attempt) {
							// 	attempt = true
							// 	let t = setInterval(() => {
							// 		if ( !store.getters.getIsRefreshing ) {
							// 			proceed()
							// 			clearInterval(t)
							// 		}
							// 	}, 300)
							// } else {
							// 	reject(err.message)
							// }

              reject(err.message)
						}

						if (typeof err.error !=='undefined') {
							reject(err.error)
						}
					})
			}
			proceed()			
		})
	},
  updateTableIndexes: async ({ commit }, payload) => {
		return new Promise((resolve, reject) => {
			function proceed() {
				commit("SET_UPDATE_INDEXES_LOADING", true)

        let { for_dispatch, ...otherData } = payload

        if (!for_dispatch) {
          axios.put(`${poBaseUrl}/edit-index-view`, otherData)
					.then(res => {
						commit("SET_UPDATE_INDEXES_LOADING", false)
						if (typeof res!=='undefined' && res.status === 200) {
							resolve(res.data)
						}
					})
					.catch(err => {
						commit('SET_UPDATE_INDEXES_LOADING', false)

						if (typeof err.message!=='undefined') {
              reject(err.message)
						}

						if (typeof err.error !=='undefined') {
							reject(err.error)
						}
					})
        } else {
          axios.post(`${poBaseUrl}/edit-dispach-legs`, otherData)
					.then(res => {
						commit("SET_UPDATE_INDEXES_LOADING", false)
						if (typeof res!=='undefined' && res.status === 200) {
							resolve(res.data)
						}
					})
					.catch(err => {
						commit('SET_UPDATE_INDEXES_LOADING', false)

						if (typeof err.message!=='undefined') {
              reject(err.message)
						}

						if (typeof err.error !=='undefined') {
							reject(err.error)
						}
					})
        }
			}
			proceed()			
		})
	},
  fetchAllConsignees: async ({ commit }) => {
    commit("SET_CONSIGNEES_LOADING", true);
    commit("SET_ALL_CONSIGNEES", []);

    await axios
      .get(`${poBaseUrl}/get-consignees-for-trucker`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_CONSIGNEES_LOADING", false);
            commit("SET_ALL_CONSIGNEES", res.data);
            return Promise.resolve("ok");
          } else {
            return Promise.reject("not ok");
          }
        } else {
          return Promise.reject("not ok");
        }
      })
      .catch((err) => {
        commit("SET_CONSIGNEES_LOADING", false);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
};
const mutations = {
  CURRENT_SCHEDULE_FILTER_STATE: (state, payload) => {
    state.scheduleFilterState = payload;
  },
  CURRENT_TAB_SELECTED: (state, payload) => {
    state.currentTabSelected = payload;
  },
  EDITVIEWDATA: (state, payload) => {
    state.editViewData = payload;
  },
  SET_STATUS_LOADING: (state, payload) => {
    state.statusLoading = payload;
  },
  SET_CONTAINERS: (state, payload) => {
    state.allcontainers = payload;
  },
  SET_EDIT_DETAILS: (state, payload) => {
    state.editDetails = payload;
  },
  SET_FORM_DETAILS: (state, payload) => {
    state.containersFormDetails = payload;
  },
  SET_PO_LOADING: (state, payload) => {
    state.poLoading = payload;
  },
  SET_CON_PENDING: (state, payload) => {
    state.pendingcontainers = payload;
  },
  SET_CON_AVAILABLE: (state, payload) => {
    state.availablecontainers = payload;
  },
  SET_CON_YARDFULL: (state, payload) => {
    state.yardfullcontainers = payload;
  },
  SET_CON_ASSIGNEDCONTAINERS: (state, payload) => {
    state.assignedcontainers = payload;
  },
  SET_CON_DROPFULLCONTAINERS: (state, payload) => {
    state.droppedfullcontainers = payload;
  },
  SET_CON_DROPEMPTY: (state, payload) => {
    state.droppedemptycontainers = payload;
  },
  SET_PENDINGBILLING: (state, payload) => {
    state.pendingbillingcontainers = payload;
  },
  SET_COMPLETED: (state, payload) => {
    state.completed = payload;
  },
  SET_CON_ALLTABCOUNTER: (state, payload) => {
    state.tabcounter = payload;
  },
  ALL_CONTAINER_STATUSES: (state, payload) => {
    state.allcontainersStatuses = payload;
  },
  SET_TAB_LOADING: (state, payload) => {
    state.tabloading = payload;
  },
  FETCH_ALL_CONTAINERS: (state, payload) => {
    state.fetchContainerdetails = payload;
  },
  FETCH_ALL_CARRIERS: (state, payload) => {
    state.fetchcarriers = payload;
  },
  SET_CONSIGNEES: (state, payload) => {
    state.consigneeLists = payload;
  },
  SET_CONSIGNEES_LOADING: (state, payload) => {
    state.consigneeLoading = payload;
  },
  SET_CREATE_CONSIGNEES_LOADING: (state, payload) => {
    state.createConsigneeLoading = payload;
  },
  SET_UPDATE_INDEXES_LOADING: (state, payload) => {
    state.updateIndexesLoading = payload;
  },
  SET_ALL_CONSIGNEES: (state, payload) => {
    state.allConsigneeLists = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
