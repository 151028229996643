const state = {
  showSetupReport: false,
  loadingStatus: false,
  showStatic: false,
  showReportDialog: false,
  selectedReportData: {},
  isFormForEdit: false,
  reloadIndex: false,
};
const getters = {
  getShowSetupReport: (state) => state.showSetupReport,
  getLoadingStatus: (state) => state.loadingStatus,
  getShowStatic: (state) => state.showStatic,
  getShowReportDialog: (state) => state.showReportDialog,
  getSelectedReportData: (state) => state.selectedReportData,
  getIsFormForEdit: (state) => state.isFormForEdit,
  getSetReloadIndex: (state) => state.reloadIndex,

};
const actions = {
  // async getCompanyReports(state){
  //   await axios.get(`${APIBaseUrl}/reports/company`)  
  //   .then((res) => {
  //     if(res.data){
  //     }
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // },
  
};
const mutations = {
  SET_SHOW_SETUP_REPORT: (state, payload) => {
    state.showSetupReport = payload;
  },
  SET_SHOW_STATIC: (state, payload) => {
    state.showStatic = payload;
  },
  SET_SHOW_COMPANY_REPORT_DIALOG: (state, payload) => {
    state.showReportDialog = payload;
  },
  SET_SELECTED_COMPANY_REPORT: (state, payload) => {
    state.selectedReportData = payload;
  },
  SET_COMPANY_REPORT_FOR_EDIT: (state,payload) => {
    state.isFormForEdit = payload;
  },
  SET_COMPANY_REPORT_RELOAD_INDEX: (state,payload) => {
    state.reloadIndex = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
