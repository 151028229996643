export default {
    name      :  'Shifl Accounting Module',

    code      :  'vue',

    major     :  '1',

    minor     :  '0',

    patch     :  '0',

    build     :  '',

    status    :  'alpha',

    date      :  '01-February-2023',

    time      :  '01:00',

    zone      :  'GMT +0',
}