/*eslint no-useless-catch: "off"*/
/*eslint no-empty-pattern: "off"*/

import AccountingService from '../services/accounting.service';
import version from '../version';

const storageData = localStorage.getItem('shifl.accounting');

const state = () =>
  JSON.parse(storageData) || {
    itemList: [],
    expenseAccountLists: [],
    incomeAccountLists: [],
    QBOExpenseAccountLists: [],
    QBOIncomeAccountLists: [],
    QBOInventoryExpenseAccountLists: [],
    QBOInventoryIncomeAccountLists: [],
    QBOInventoryAssetAccountLists: [],
    isQBOAccountLoading: false,
    currencies: [],
    companyInformation: null,
    QBOInformation: null,
    isCheckingAccounting: true,
    isCheckingQBO: false,
    accountingLastPath: null,
    user: null,
    quickBooksToken: {},
    currentAccountingPageIndex: 0,
    defaults: null,
  };

// MUTATIONS
const mutations = {
  clearState: (state, payload) => {
    Object.keys(payload).forEach((key) => {
      state[key] = payload[key];
    });
  },

  updateCurrentPageIndex: (state, payload) => {
    state.currentAccountingPageIndex = payload;
  },

  updateAccountingCompany: (state, payload) => {
    state.companyInformation = payload;
  },

  updateQBO: (state, payload) => {
    state.QBOInformation = payload;
  },

  updateQuickbooksItemList: (state, payload) => {
    state.itemList = payload;
  },

  updateAkauntingExpenseAccountLists: (state, payload) => {
    state.expenseAccountLists = payload;
  },

  updateAkauntingIncomeAccountLists: (state, payload) => {
    state.incomeAccountLists = payload;
  },

  updateQBOIncomeAccountLists: (state, payload) => {
    state.QBOIncomeAccountLists = payload;
  },

  updateQBOExpenseAccountLists: (state, payload) => {
    state.QBOExpenseAccountLists = payload;
  },

  updateQBOInventoryIncomeAccountLists: (state, payload) => {
    state.QBOInventoryIncomeAccountLists = payload;
  },

  updateQBOInventoryExpenseAccountLists: (state, payload) => {
    state.QBOInventoryExpenseAccountLists = payload;
  },

  updateQBOAssetAccountLists: (state, payload) => {
    state.QBOInventoryAssetAccountLists = payload;
  },

  updateisQBOAccountLoading: (state, payload) => {
    state.isQBOAccountLoading = payload;
  },

  updateCurrencies: (state, payload) => {
    state.currencies = payload;
  },

  updateAccountingLastPath: (state, payload) => {
    state.accountingLastPath = payload;
  },

  updateCheckingAccounting: (state, payload) => {
    state.isCheckingAccounting = payload;
  },

  updateCheckingQBO: (state, payload) => {
    state.isCheckingQBO = payload;
  },

  updateUser: (state, payload) => {
    state.user = payload;
  },

  setDefaults: (state, payload) => {
    state.defaults = payload;
  },
};

// GETTERS
const getters = {
  getCurrentPageIndex(state) {
    return state.currentAccountingPageIndex;
  },
  isQBOEnabled(state) {
    return state.companyInformation &&
      Boolean(state.companyInformation?.accounting_company?.qbo_realm_id) &&
      state.QBOInformation
      ? 1
      : 0;
  },

  homeCurrency(state) {
    return state.companyInformation?.accounting_details?.currency || 'USD';
  },

  getDEtypes() {
    return {
      'double-entry::types.current_asset': 'Current Asset',
      'double-entry::types.fixed_asset': 'Fixed Asset',
      'double-entry::types.inventory': 'Inventory',
      'double-entry::types.non_current_asset': 'Non-current Asset',
      'double-entry::types.prepayment': 'Pre-Payment',
      'double-entry::types.bank_cash': 'Bank & Cash',
      'double-entry::types.current_liability': 'Current Liability',
      'double-entry::types.liability': 'Liability',
      'double-entry::types.non_current_liability': 'Non-current Liability',
      'double-entry::types.depreciation': 'Depreciation',
      'double-entry::types.direct_costs': 'Direct Costs',
      'double-entry::types.expense': 'Expense',
      'double-entry::types.revenue': 'Revenue',
      'double-entry::types.sales': 'Sales',
      'double-entry::types.other_income': 'Other Income',
      'double-entry::types.equity': 'Equity',
      'double-entry::types.tax': 'Tax'
    };
  },

  getUserToken: (state) => state?.user?.token?.accessToken,

  getCompanyDefaults:  (state) => state?.companyInformation?.defaults,

  getFrontendVersion: () => {
    return version.name + ' ' + 'v' + version.major + '.' + version.minor + '.' + version.patch;
  },

};

// ACTIONS
const actions = {
  clearData: ({ commit }) => {
    localStorage.removeItem('shifl.accounting');
    commit('clearState', {
      itemList: [],
      expenseAccountLists: [],
      incomeAccountLists: [],
      QBOExpenseAccountLists: [],
      QBOIncomeAccountLists: [],
      QBOInventoryExpenseAccountLists: [],
      QBOInventoryIncomeAccountLists: [],
      QBOInventoryAssetAccountLists: [],
      isQBOAccountLoading: false,
      currencies: [],
      companyInformation: null,
      QBOInformation: null,
      isCheckingAccounting: true,
      isCheckingQBO: false,
      accountingLastPath: null,
      user: null,
      quickBooksToken: {},
      currentAccountingPageIndex: 0,
      defaults: null,
    });
  },

  clearAccountingCompanyData: ({ commit }) => {
    commit('clearState', {
      itemList: [],
      expenseAccountLists: [],
      incomeAccountLists: [],
      QBOExpenseAccountLists: [],
      QBOIncomeAccountLists: [],
      QBOInventoryExpenseAccountLists: [],
      QBOInventoryIncomeAccountLists: [],
      QBOInventoryAssetAccountLists: [],
      isQBOAccountLoading: false,
      currencies: [],
      companyInformation: null,
      QBOInformation: null,
      isCheckingAccounting: true,
      isCheckingQBO: false,
      accountingLastPath: null,
      quickBooksToken: {},
      currentAccountingPageIndex: 0,
      defaults: null,
    });
  },

  setAccountingPageIndex: ({ commit }, payload) => {
    commit('updateCurrentPageIndex', payload);
  },

  loginUser: async ({ commit }, payload) => {
    try {
      const { data } = await AccountingService.loginUser(payload);
      commit('updateUser', data?.data);
    } catch (error) {
      commit('SET_ERROR_MESSAGE', 'Accounting login error', { root: true });
      throw error;
    }
  },

  setQuickbooksAccessToken: async ({}, { code, realmId }) => {
    try {
      return await AccountingService.exchangeToken({
        code,
        realmId
      });
    } catch (error) {
      throw error;
    }
  },

  createAccountingCompany: async ({ commit }, payload) => {
    try {
      const { data } = await AccountingService.createAccountingCompany(payload);
      commit('updateAccountingCompany', data.data || null);
      if(data.data){
        return data;
      }
    } catch (error) {
      throw error;
    }
  },

  getQuickbooksLoginUrl: async () => {
    try {
      const { data } = await AccountingService.getQBOLogin();

      // Determine here if the returned data contains URL
      if (data.data && data.data.authUrl) {
        return data.data.authUrl;
      }

      return null;
    } catch (error) {
      throw error;
    }
  },

  signOutQBO: async () => {
    try {
      const { data } = await AccountingService.signOutQBO();
      return data;
    } catch (error) {
      throw error;
    }
  },

  getAccounting: async ({ commit }) => {
    try {
      commit('updateCheckingAccounting', true);
      const { data } = await AccountingService.pingAccounting();
      commit('updateCheckingAccounting', false);
      return data;
    } catch (error) {
      commit('updateCheckingAccounting', false);
      throw error;
    }
  },

  getQBOConnection: async ({ commit }) => {
    try {
      commit('updateCheckingQBO', true);
      const { data } = await AccountingService.pingQBO();
      commit('updateCheckingQBO', false);
      return data;
    } catch (error) {
      commit('updateCheckingQBO', false);
      throw error;
    }
  },

  getAkauntingCategoryExpense: async ({}) => {
    try {
      const { data } = await AccountingService.getAkauntingCategoryExpense();
      return data?.data || [];
    } catch (error) {
      throw error;
    }
  },

  getQuickbooksItemsByCompany: async ({ commit }, company_id) => {
    try {
      const res = await AccountingService.getQuickbooksItemsByCompany(company_id);
      const responseData = res.data;
      if (responseData.data.length > 0) {
        commit('updateQuickbooksItemList', responseData.data);
      }
    } catch (error) {
      throw error;
    }
  },

  getAkauntingAccounts: async ({ commit }) => {
    try {
      const { data } = await AccountingService.getAkauntingAccounts();
      if (data.data && data.data.income_accounts) {
        commit('updateAkauntingIncomeAccountLists', data.data.income_accounts);
      }
      if (data.data && data.data.expense_accounts) {
        commit('updateAkauntingExpenseAccountLists', data.data.expense_accounts);
      }
    } catch (error) {
      // Do nothing. Log this error in the future
    }
  },

  getQBOInventoryAssetAccounts: async ({ commit }) => {
    try {
      const response = await AccountingService.getQBOInventoryAssetAccounts();
      commit('updateQBOAssetAccountLists', response.data || []);
    } catch (error) {
      // Do nothing
    }
  },

  getQBOInventoryExpenseAccounts: async ({ commit }) => {
    try {
      const response = await AccountingService.getQBOInventoryExpenseAccounts();
      commit('updateQBOInventoryExpenseAccountLists', response.data || []);
    } catch (error) {
      // Do nothing
    }
  },

  getQBOInventoryIncomeAccounts: async ({ commit }) => {
    try {
      const response = await AccountingService.getQBOInventoryIncomeAccounts();
      commit('updateQBOInventoryIncomeAccountLists', response.data || []);
    } catch (error) {
      // Do nothing
    }
  },

  getQBOIncomeAccounts: async ({ commit }) => {
    try {
      const response = await AccountingService.getQBOIncomeAccounts();
      commit('updateQBOIncomeAccountLists', response.data || []);
    } catch (error) {
      console.log(error);
    }
  },

  getQBOExpenseAccounts: async ({ commit }) => {
    try {
      const response = await AccountingService.getQBOExpenseAccounts();
      commit('updateQBOExpenseAccountLists', response.data || []);
    } catch (error) {
      console.log(error);
    }
  },

  getQBOAccounts: async ({ commit, dispatch }, QBOItemType) => {
    try {
      commit('updateisQBOAccountLoading', true);

      if (QBOItemType === 'Inventory') {
        await Promise.all([
          dispatch('getQBOInventoryAssetAccounts'),
          dispatch('getQBOInventoryExpenseAccounts'),
          dispatch('getQBOInventoryIncomeAccounts')
        ]);
      }

      if (['NonInventory', 'Service'].includes(QBOItemType)) {
        await Promise.all([dispatch('getQBOIncomeAccounts'), dispatch('getQBOExpenseAccounts')]);
      }

      commit('updateisQBOAccountLoading', false);
    } catch (error) {
      // Do nothing. Log this error in the future
      commit('updateisQBOAccountLoading', false);
    }
  },

  // Item API
  createItemForm: async ({}, payload) => {
    try {
      return await AccountingService.createItem(payload);
    } catch (error) {
      throw error;
    }
  },

  updateItemForm: async ({}, payload) => {
    try {
      return await AccountingService.updateItem(payload);
    } catch (error) {
      throw error;
    }
  },

  disableItem: async ({ getters }, payload) => {
    try {
      const { data } = await AccountingService.disableItem(payload, { qbo_enabled: getters.isQBOEnabled });
      return data;
    } catch (error) {
      throw error;
    }
  },

  getItemsData: async ({}, payload) => {
    try {
      const response = await AccountingService.getItems(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  // Category Based Items API

  getCategoryBasedItemData: async ({}, payload) => {
    try {
      const response = await AccountingService.getCategoryBasedItems(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createCategoryBasedItemForm: async ({}, payload) => {
    try {
      return await AccountingService.createCategoryBasedItem(payload);
    } catch (error) {
      throw error;
    }
  },

  updateCategoryBasedItemForm: async ({}, payload) => {
    try {
      return await AccountingService.updateCategoryBasedItem(payload);
    } catch (error) {
      throw error;
    }
  },

  getQBOParentAccounts: async () => {
    try {
      const response = await AccountingService.getQBOParentAccounts();
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // Customers API

  getCustomersData: async ({}, payload) => {
    try {
      const response = await AccountingService.getCustomers(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createCustomerForm: async ({}, payload) => {
    try {
      return await AccountingService.createCustomer(payload);
    } catch (error) {
      throw error;
    }
  },

  updateCustomerForm: async ({}, payload) => {
    try {
      return await AccountingService.updateCustomer(payload);
    } catch (error) {
      throw error;
    }
  },

  deleteCustomer: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteCustomer(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  // Vendors API

  getVendorsData: async ({}, payload) => {
    try {
      const response = await AccountingService.getVendors(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createVendorForm: async ({}, payload) => {
    try {
      return await AccountingService.createVendor(payload);
    } catch (error) {
      throw error;
    }
  },

  updateVendorForm: async ({}, payload) => {
    try {
      return await AccountingService.updateVendor(payload);
    } catch (error) {
      throw error;
    }
  },

  getCurrencies: async ({ commit }) => {
    try {
      const response = await AccountingService.getCurrencies();
      commit('updateCurrencies', response?.data?.data?.data || []);
    } catch (error) {
      return;
    }
  },

  disableVendor: async ({ getters }, payload) => {
    try {
      return await AccountingService.disableVendor(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  // Billing API
  createBillingForm: async ({}, payload) => {
    try {
      return await AccountingService.createBilling(payload);
    } catch (error) {
      throw error;
    }
  },

  getBillsData: async ({}, payload) => {
    try {
      const response = await AccountingService.getBills(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  updateBillForm: async ({}, payload) => {
    try {
      return await AccountingService.updateBill(payload.id, payload);
    } catch (error) {
      throw error;
    }
  },

  deleteBill: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteBill(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  getBill: async ({}, payload) => {
    try {
      return await AccountingService.getBill(payload);
    } catch (error) {
      throw error;
    }
  },

  billReceived: async ({ getters }, payload) => {
    try {
      return await AccountingService.receiveBill(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  billCancelled: async ({ getters }, payload) => {
    try {
      return await AccountingService.receiveBill(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  // Use when signing-in with QBO Oauth2
  setLastUrlPath: ({ commit }, payload) => {
    commit('updateAccountingLastPath', payload);
  },

  // Invoice Form

  createInvoiceForm: async ({}, payload) => {
    try {
      return await AccountingService.createInvoice(payload);
    } catch (error) {
      throw error;
    }
  },

  getInvoicesData: async ({}, payload) => {
    try {
      const response = await AccountingService.getInvoices(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateInvoice: async ({}, { id, payload }) => {
    try {
      return await AccountingService.updateInvoice(id, payload);
    } catch (error) {
      throw error;
    }
  },

  deleteInvoice: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteInvoice(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },
  ////// STATEMENT //////
  getStatements: async ({}, payload) => {
    try {
      const response = await AccountingService.getStatements(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  getStatement: async ({}, payload) => {
    try {
      const response = await AccountingService.getStatement(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createStatementForm: async ({}, payload) => {
    try {
      return await AccountingService.createStatement(payload);
    } catch (error) {
      throw error;
    }
  },

  updateStatementForm: async ({}, payload) => {
    try {
      return await AccountingService.updateStatement(payload);
    } catch (error) {
      throw error;
    }
  },
  deleteStatement: async ({}, payload) => {
    try {
      return await AccountingService.deleteStatement(payload);
    } catch (error) {
      throw error;
    }
  },
  //////////////////////

  markInvoiceAsSent: ({}, id) => {
    return AccountingService.markInvoiceAsSent(id);
  },

  markInvoiceAsCancelled: ({}, id) => {
    return AccountingService.markInvoiceAsCancelled(id);
  },

  getAccountingCurrencies: async ({}, payload) => {
    try {
      const response = await AccountingService.getAccountingCurrencies(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createCurrencyForm: async ({}, payload) => {
    try {
      const response = await AccountingService.createCurrencyForm(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateCurrencyForm: async ({}, payload) => {
    try {
      return await AccountingService.updateCurrency(payload);
    } catch (error) {
      throw error;
    }
  },

  deleteCurrency: async ({}, payload) => {
    try {
      return await AccountingService.deleteCurrency(payload);
    } catch (error) {
      throw error;
    }
  },

  // Accounts

  getAccountsData: async ({}, payload) => {
    try {
      const response = await AccountingService.getAccounts(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createAccountForm: async ({}, payload) => {
    try {
      const response = await AccountingService.createAccountForm(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateAccountForm: async ({}, payload) => {
    try {
      const response = await AccountingService.updateAccountForm(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteAccount: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteAccount(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  disableAccountBasedItem: async ({ getters }, payload) => {
    try {
      const { data } = await AccountingService.disableAccountBasedItem(payload, { qbo_enabled: getters.isQBOEnabled });
      return data;
    } catch (error) {
      throw error;
    }
  },

  getQBOAccountTypes: async ({}, payload) => {
    try {
      const response = await AccountingService.getQBOAccountTypes(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  // Categories
  getCategories: async ({}, payload) => {
    try {
      const response = await AccountingService.getCategories(payload);
      return response.data?.data || [];
    } catch (error) {
      throw error;
    }
  },

  // Chart of Accounts
  getCoaAccountsAndTypes: async ({}, payload) => {
    try {
      const response = await AccountingService.getCoaAccountsAndTypes(payload);
      return response.data?.data?.data || {};
    } catch (error) {
      throw error;
    }
  },

  getCoaAccountsAndDefaults: async ({}, payload) => {
    try {
      const response = await AccountingService.getCoaAccountsAndDefaults(payload);
      return response.data?.data?.data || {};
    } catch (error) {
      throw error;
    }
  },

  getChartAccountAndTypes: async ({}, payload) => {
    try {
      const response = await AccountingService.getAccountAndTypes(payload);
      return response.data?.data?.data || {};
    } catch (error) {
      throw error;
    }
  },

  getChartAccounts: async ({}, payload) => {
    try {
      const response = await AccountingService.getChartAccounts(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createCoaAccount: async ({}, payload) => {
    try {
      const response = await AccountingService.createCoaAccount(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateCoaAccount: async ({}, payload) => {
    try {
      const response = await AccountingService.updateCoaAccount(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteCoaAccount: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteCoaAccount(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  enableCoaAccount: async ({ getters }, payload) => {
    try {
      return await AccountingService.enableCoaAccount(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  disableCoaAccount: async ({ getters }, payload) => {
    try {
      return await AccountingService.disableCoaAccount(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  // TaxRate API
  getTaxRates: async ({}, payload) => {
    try {
      const response = await AccountingService.getTaxRates(payload);
      return response.data?.data || null;
    } catch (error) {
      throw error;
    }
  },

  createTaxRate: async ({}, payload) => {
    try {
      const response = await AccountingService.createTaxRate(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateTaxRate: async ({}, payload) => {
    try {
      const response = await AccountingService.updateTaxRate(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  deleteTaxRate: async ({}, payload) => {
    try {
      const response = await AccountingService.deleteTaxRate(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  // Invoice Payment API
  getInvoicePaymentData: async ({}, payload) => {
    try {
      const response = await AccountingService.getInvoicePayments(payload);
      return response.data?.data || null;
    } catch (error) {
      throw error;
    }
  },

  createInvoicePayment: async ({}, payload) => {
    try {
      const response = await AccountingService.createInvoicePayment(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateInvoicePayment: async ({}, { id, payload }) => {
    try {
      const response = await AccountingService.updateInvoicePayment(id,payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  fetchDefaults: async ({ commit }) => {
    const { data } = await AccountingService.getAccountingDefaults();
    commit('setDefaults', data.data);
  },

  // Bill Payment API
  getBillPaymentData: async ({}, payload) => {
    try {
      const response = await AccountingService.getBillPayments(payload);
      return response.data?.data || null;
    } catch (error) {
      throw error;
    }
  },

  createBillPayment: async ({}, payload) => {
    try {
      const response = await AccountingService.createBillPayment(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateBillPayment: async ({}, { id, payload }) => {
    try {
      const response = await AccountingService.updateBillPayment(id,payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  // Transactions API
  deleteTransaction: async ({}, payload) => {
    try {
      const response = await AccountingService.deleteTransaction(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  // Journal Entries API
  getJournalEntriesData: async ({}, payload) => {
    try {
      const response = await AccountingService.getJournalEntries(payload);
      return response.data.data;
    } catch (error) {
      throw error;
    }
  },

  createJournalEntry: async ({}, payload) => {
    try {
      const response = await AccountingService.createJournalEntry(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateJournalEntry: async ({}, { id, payload }) => {
    try {
      const response = await AccountingService.updateJournalEntry(id,payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },
  
  deleteJournalEntry: async ({ getters }, payload) => {
    try {
      return await AccountingService.deleteJournalEntry(payload, { qbo_enabled: getters.isQBOEnabled });
    } catch (error) {
      throw error;
    }
  },

  // Reconciliations API
  createReconciliation: async ({}, payload) => {
    try {
      const response = await AccountingService.createReconciliation(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  updateReconciliation: async ({}, { id, payload }) => {
    try {
      const response = await AccountingService.updateReconciliation(id,payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },

  deleteReconciliation: async ({}, payload) => {
    try {
      const response = await AccountingService.deleteReconciliation(payload);
      return response.data || [];
    } catch (error) {
      throw error;
    }
  },
  
  getReconciliation: async ({}, payload) => {
    try {
      const response = await AccountingService.getReconciliation(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getReconciliations: async ({}, payload) => {
    try {
      const response = await AccountingService.getReconciliations(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAccountOpeningBalance: async ({}, payload) => {
    try {
      const response = await AccountingService.getAccountOpeningBalance(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getAccountTransactionsByPeriod: async ({}, payload) => {
    try {
      const response = await AccountingService.getAccountTransactionsByPeriod(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCompanyPreferences: async ({}, payload = {}) => {
    try {
      const response = await AccountingService.getCompanyPreferences(payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
