import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import "./assets/css/main.css";
import "./assets/scss/global.scss";
import router from "./router";
import vuetify from "./plugins/vuetify";
import vuetifyMoney from "./plugins/vuetify-money.js";
import axios from "axios";
import "izitoast/dist/css/iziToast.min.css";
import Echo from "laravel-echo";
import { registerAccountingModule } from "./register-accounting-module";
import 'devextreme/dist/css/dx.light.css';
import vCalendar from './plugins/vc-calendar';
import DatePicker from './plugins/vc-date-picker';

window.Pusher = require("pusher-js");
// window.Event = new Vue();
Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = "http://db505f5cfa37.ngrok.io/api"
axios.defaults.headers.common.Accept = "application/json";
// adding token by interceptors
axios.interceptors.request.use(function (config) {
  const token = "Bearer " + store.getters.getUserToken;
  config.headers.Authorization = token;
  return config;
});

// refresh token by using interceptors

let isRefreshing = false;
// let subscribers = [];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err) => {
    const {
      config,
      response: { status, data },
    } = err;

    const originalRequest = config;

    if (store.getters.getUserToken === "") {
      //store.dispatch("logout")
      return Promise.reject(err);
    }

    if (originalRequest.url.includes("refresh-token")) {
      store.dispatch("logout");
      return Promise.reject(err);
    }

    if (status == 500 || status == 400) {
      return Promise.reject(data);
    }

    if (status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        // store
        // 	.dispatch("refreshToken")
        // 	.then((res) => {
        // 		if (res.status === 200 || res.status == 204) {
        // 			isRefreshing = false;
        // 		}

        // 		console.log("What is Stat2e", store.state)
        // 		// originalRequest.headers.Authorization = "Bearer " + res.data.token
        // 		originalRequest._retry = true
        // 		console.log("What is reset Token", originalRequest)
        // 		return axios(originalRequest)
        // 		// subscribers = [];
        // 	})
        // 	.catch(error => {
        // 		return Promise.reject(error);
        // 	});

        await store.dispatch("refreshToken");
        isRefreshing = false;
        originalRequest.headers.Authorization =
          "Bearer " + store.state.auth.user_token;
        return axios(originalRequest);
      }

      // const requestSubscribers = new Promise(resolve => {
      // 	subscribeTokenRefresh(() => {
      // 		resolve(axios(originalRequest));
      // 	});
      // });

      // onRefreshed();

      // return requestSubscribers;
    } else {
      return Promise.reject(data);
      // if (status === 401) {
      // 	return Promise.reject(data);
      // }
    }
  }
);

// function subscribeTokenRefresh(cb) {
// 	subscribers.push(cb);
// }

// function onRefreshed() {
// 	subscribers.map(cb => cb());
// }

// subscribers = [];
///

// console.log(axios.defaults.headers.common.Authorization)

registerAccountingModule();

new Vue({
  store,
  router,
  vuetify,
  methods: {
    connectToWebsockets() {
      if (!store.getters.getUserToken) return;
      console.log("now connecting to websockets.....");
      window.Echo = new Echo({
        broadcaster: "pusher",
        key: process.env.VUE_APP_TRUCKING_PUSHER_KEY,
        wsHost: new window.URL(process.env.VUE_APP_WS_URL).hostname,
        wsPort: 6001,
        wssPort: 6001,
        authEndpoint: process.env.VUE_APP_PO_URL + "/broadcasting/auth",
        enabledTransports: ["wss", "ws"],
        auth: {
          headers: {
            Authorization: "Bearer " + store.getters.getUserToken,
            Accept: "application/json",
          },
        },
      });
      window.Echo.connector.pusher.connection.bind("connected", () => {
        console.log("connected");
      });
    },
  },
  created() {
    this.connectToWebsockets();
  },
  vuetifyMoney,
  vCalendar,
  DatePicker,
  render: (h) => h(App),
}).$mount("#app");
