<template>
    <v-dialog persistent v-model="openFeedbackSuccess" max-width="590px" max-height="600px" content-class="add-supplier-dialog add-driver-dialog" :retain-focus="false">
        <v-card class="add-supplier-card">
            <v-card-title style=" caret-color: transparent;">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="fb-icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

            </v-card-title>

            <div class="pa-7">
                <div class="mb-3">
                    <span class="fb-text">Feedback Submitted</span>
                </div>
                <div>
                    <span class="fb-sub-text">We have received your feedback. Thanks for informing us. We will get back to you with update.</span>
                </div>
            </div>
            
            <v-card-actions>
                <v-btn class="btn-blue mr-2" text @click="close">
                    <span>
                        <span>
                            Understood
                        </span>
                    </span>
                </v-btn>                   

                <v-btn class="btn-white" @click="closeOnSMF">
                    Share More Feedback
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FeedbackDialog',
    props: ["openFeedbackSuccess"],
    methods: {
        close() {
            this.$emit('closeDialogSuccess')
        },
        closeOnSMF() {
            this.$emit('closeOnSMF')
        }
    },
}
</script>

<style>
@import '../../assets/css/dialog_styles/deleteDialog.css';
.fb-text {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4A4A4A;
}
.fb-sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4A4A4A;
}
.fb-icon {
    width: 54px;
    color: green;
}
</style>