const truckUrl = process.env.VUE_APP_MAPBOX_ROUTE_API_URL;
const accessToken = process.env.VUE_APP_MAPBOX_PUBLIC_KEY;
import polyline from "@mapbox/polyline";
const APIBaseUrl = process.env.VUE_APP_PO_URL;
import axios from "axios";
// import { dummyStore } from "../../DummyData";
import mapboxgl from "mapbox-gl";

const state = {
  data: [],
  polylinesData: [],
  polylineMeasurement: {
    routingStats: false,
    length: "",
    time: "",
  },
  dispatchesInfo: Object,
  showRoutingInfo: false,
};

const getters = {
  getPolylineMeasurement: (state) => state.polylineMeasurement,
  getData: (state) => state.data,
  getPolylinesData: (state) => state.polylinesData,
  getDispatchesInfo: (state) => state.dispatchesInfo,
  getShowRoutingInfo: (state) => state.showRoutingInfo,
};
const actions = {
  getDispatchesForDriver(context) {
    const mapInstance = context.rootGetters["map/getMapInstance"];
    if (!mapInstance) return;
    axios
      .get(`${APIBaseUrl}/getAllDispatches`)

      .then((res) => {
        console.log(res.data);
        context.commit("SET_DATA", res.data);

        setTimeout(function () {
          context.dispatch("filterDataForRouting");
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  filterDataForRouting(context) {
    const data = context.getters.getData;
    const filterA = data.filter((element) => {
      return (
        element.location_from_longitude &&
        element.location_from_latitude &&
        element.addressLongitude &&
        element.addressLatitude
      );
    });
    context.commit("SET_POLYLINES_DATA", filterA);
    context.dispatch("getRoutesFromMapBoxApi");
    const info = {
      totalDispatches: data.length,
      afterFilteration: filterA.length,
    };
    context.commit("SET_DISPATCHES_IFNO", info);
  },
  getRoutesFromMapBoxApi(context) {
    const data = context.getters.getPolylinesData;
    data.forEach((element) => {
      console.log(element);
      let newUrl = `${truckUrl}/${element.location_from_longitude},${element.location_from_latitude};${element.addressLongitude},${element.addressLatitude}?access_token=${accessToken}&steps=true`;
      fetch(newUrl)
        .then((response) => response.json())
        .then((json) => {
          if (json.code === "InvalidInput") {
            console.log(
              `route is not possible btween ${element.address} and ${element.location_from}`
            );
            return;
          }
          if (json.routes[0].geometry) {
            const data = {
              geoJson: json.routes[0].geometry,
              driverInfo: {
                driverID: element.driver,
                trucker_container: element.trucker_container,
              },
            };
            context.dispatch("displayPolyineOnMap", data);
          }
        });
    });
  },
  displayPolyineOnMap(context, data) {
    const decodedGeoJson = polyline.toGeoJSON(data.geoJson);
    const mapInstance = context.rootGetters["map/getMapInstance"];
    mapInstance.addLayer({
      id: `${new Date().valueOf()}`,
      type: "line",
      source: {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: decodedGeoJson.coordinates,
          },
        },
      },
      layout: {
        "line-join": "miter",
        "line-cap": "square",
      },
      paint: {
        "line-color": (Math.random() < 0.5 ? 0 : 1 == 1) ? "red" : "blue",
        "line-width": 2,
      },
    });
    const length = decodedGeoJson.coordinates.length;
    new mapboxgl.Marker()
      .setLngLat(decodedGeoJson.coordinates[0])
      .setPopup(
        new mapboxgl.Popup().setHTML(
          `<h3>Driver Id ${data.driverInfo.driverID}</h3><h4>trucker_container ${data.driverInfo.trucker_container}</h4><p>Dispatch Starting point!</p>`
        )
      ) // add popup
      .addTo(mapInstance);
    new mapboxgl.Marker()
      .setLngLat(decodedGeoJson.coordinates[length - 1])
      .setPopup(new mapboxgl.Popup().setHTML("<h3>Dispatch Ending point!</h3>")) // add popup
      .addTo(mapInstance);
  },
};
const mutations = {
  SET_ROUTING_STATS: (state, payload) => {
    state.routingStatus = payload;
  },
  SET_POLYLINE_MEASUREMENT: (state, payload) => {
    state.polylineMeasurement.routingStats = true;
    state.polylineMeasurement.time = payload.time;
    state.polylineMeasurement.length = payload.length;
  },
  SET_DATA: (state, payload) => {
    state.data = payload;
  },
  SET_POLYLINES_DATA: (state, payload) => {
    state.polylinesData = payload;
  },
  SET_DISPATCHES_IFNO: (state, payload) => {
    state.dispatchesInfo.totalDispatches = payload.totalDispatches;
    state.dispatchesInfo.afterFilteration = payload.afterFilteration;
    state.showRoutingInfo = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
