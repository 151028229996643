import axios from "axios";

const state = {
  drivers: [],
  driversLoading: false,
  createDriver: null,
  updateDriver: null,
  updateDriverLoading: false,
  createDriverLoading: false,
  driverSaveAddLoading: false,
  trucker_id: null,
  driveraccountholder: [],
  gobackState:0,
  notificationstatus:0,
  passdata: [],
  locationFrom:'',
  resultnotification:'',
  detailNotifcation:'',
  getdriverwithshipment:[],
};

const getters = {
  getDrivers: (state) => state.drivers,
  getDriverLoading: (state) => state.driversLoading,
  getCreateDrivers: (state) => state.createDriver,
  getCreateDriverLoading: (state) => state.createDriverLoading,
  getUpdateDrivers: (state) => state.updateDriver,
  getUpdateDriversLoading: (state) => state.updateDriverLoading,
  getDriversSaveAddLoading: (state) => state.driverSaveAddLoading,
  getTruckerId: (state) => state.trucker_id,
  getDriverAccountHolder: (state) => state.driveraccountholder,
  getBackState: (state) => state.gobackState,
  getNotificationStatus: (state) => state.notificationstatus,
  getpassData: (state) => state.passdata,
  getLocFrom:  (state) => state.locationFrom,
  getNoficationResult : (state) => state.resultnotification,
  getDetailsNotification: (state) => state.detailNotifcation,
  getDriverLegShipment:(state) => state.getdriverwithshipment,
};

const APIBaseUrl = process.env.VUE_APP_PO_URL;
const actions = {  
  FetchDriverWithLegAndShipment: async ({commit},payload) => {	
    commit("SET_DRIVERS_LOADING", true);  
    commit("SET_DRIVER_LEG_SHIPMENT_RESET");      
    await axios
      .get(`${APIBaseUrl}/getLegsbyDriver/`+payload.id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_DRIVERS_LOADING", false);
            commit("SET_DRIVER_LEG_SHIPMENT", res.data);
          }
        }
      })
      .catch((err) => {
        commit("SET_DRIVERS_LOADING", false);
        return Promise.reject(err);
      });	
	},
  detailNotification: async ({commit},payload) => {	
    //commit("SET_DRIVERS_LOADING", true);    
    await axios
      .get(`${APIBaseUrl}/getnotification/`+payload.id)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            //commit("SET_DRIVERS_LOADING", false);
            commit("GET_NOTIFICATION_DATA", res.data);
          }
        }
      })
      .catch((err) => {
       // commit("SET_DRIVERS_LOADING", false);
        return Promise.reject(err);
      });	
	},
  putNotificationStatus: async ({commit},payload) => {	
    //commit("SET_DRIVERS_LOADING", true);
    commit("NOTIFICATION_PROCESS");
    await axios
      .put(`${APIBaseUrl}/saveMetaNotification/`+payload)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            //commit("SET_DRIVERS_LOADING", false);
            commit("PUT_NOTIFICATION_STATUS", res.data);
          }
        }
      })
      .catch((err) => {
        //commit("SET_DRIVERS_LOADING", false);
        return Promise.reject(err);
      });	
	},
  passLocFrom: async ({commit},payload) => {
		commit("STORELOCFROM", payload)		
	},
  passGobackData: async ({commit},payload) => {
		commit("GOBACKSTATE", payload)		
	},
  passData: async ({commit},payload) => {
		commit("PASSDATA", payload)		
	},
  fetchDriversAccountHolder: async ({ commit }) => {
    commit("SET_DRIVERS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getdriverHolder`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_DRIVERS_LOADING", false);
            commit("SET_DRIVERS_ACCOUNT_HOLDER", res.data);
          }
        }
      })
      .catch((err) => {
        commit("SET_DRIVERS_LOADING", false);
        return Promise.reject(err);
      });
  },
  fetchDrivers: async ({ commit }) => {
    commit("SET_DRIVERS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getdrivers`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit("SET_DRIVERS_LOADING", false);
            commit("SET_DRIVERS", res.data);
          }
        }
      })
      .catch((err) => {
        commit("SET_DRIVERS_LOADING", false);
        return Promise.reject(err);
      });
  },
  fetchDriversPromise: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIBaseUrl}/getdrivers`)
        .then((res) => {
          if (res.status === 200) {
            if (res.data) {
              commit("SET_DRIVERS", res.data);
              commit(
                "SET_TRUCKER_ID",
                res.data.trucker_id ? res.data.trucker_id : null,
                { root: true }
              );
              resolve("done");
            }
          }
        })
        .catch((err) => {
          reject("err", err);
          console.log(err);
        });
    });
  },
  updateSuppliers: async ({ commit }, payload) => {
    let data = {
      company_name: payload.company_name,
      address: payload.address,
      phone_number: payload.phone_number,
      emails: payload.emails,
    };

    commit(
      payload.customerMethod == "save-add"
        ? "SET_DRIVERS_SAVE_ADD_LOADING"
        : "SET_CREATE_DRIVER_LOADING",
      true
    );
    await axios
      .put(`/suppliers/${payload.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            commit(
              payload.customerMethod == "save-add"
                ? "SET_DRIVERS_SAVE_ADD_LOADING"
                : "SET_CREATE_DRIVER_LOADING",
              false
            );
            commit("SET_UPDATE_DRIVERS", res.data);
          }
        }
      })
      .catch((err) => {
        commit(
          payload.customerMethod == "save-add"
            ? "SET_DRIVERS_SAVE_ADD_LOADING"
            : "SET_CREATE_DRIVER_LOADING",
          false
        );
        return Promise.reject(err);
      });
  },
};

const mutations = {  
  SET_DRIVER_LEG_SHIPMENT_RESET: (state) => {
    state.getdriverwithshipment = '';
  },
  SET_DRIVER_LEG_SHIPMENT: (state,payload) => {
    state.getdriverwithshipment = payload;
  },
  GET_NOTIFICATION_DATA: (state,payload) => {
    state.detailNotifcation = payload;
  },
  NOTIFICATION_PROCESS: (state) => {
    state.resultnotification = 'off';
  },
  PUT_NOTIFICATION_STATUS:(state,payload) => {
    state.notificationstatus = payload;
    state.resultnotification = 'completed';
  },
  PASSDATA:(state,payload) => {
    state.passdata = payload;
  },  
  STORELOCFROM:(state,payload) => {
    state.locationFrom = payload;
  },
  GOBACKSTATE:(state,payload) => {
    state.gobackState = payload;
  },
  SET_DRIVERS_ACCOUNT_HOLDER: (state,payload) => {
    state.driveraccountholder = payload.drivers;    
  },
  SET_DRIVERS: (state, payload) => {
    state.drivers = payload.drivers;
    state.trucker_id = payload.trucker_id;
  },
  SET_DRIVERS_LOADING: (state, payload) => {
    state.driversLoading = payload;
  },
  SET_CREATE_DRIVERS: (state, payload) => {
    state.createDriver = payload;
  },
  SET_CREATE_DRIVER_LOADING: (state, payload) => {
    state.createDriverLoading = payload;
  },
  SET_DRIVERS_SAVE_ADD_LOADING: (state, payload) => {
    state.driverSaveAddLoading = payload;
  },
  SET_UPDATE_DRIVERS: (state, payload) => {
    state.updateDriver = payload;
  },
  SET_UPDATE_DRIVER_LOADING: (state, payload) => {
    state.updateDriverLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
