import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: () =>
      import(/* webpackChunkName: "/" */ "../views/Dashboard.vue"),
    meta: { requiresAuth: true },
    redirect: "/containers",
  },
  {
    path: "/login",
    name: "Login",
    // component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/forgetPassword",
    name: "ForgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ "../views/singIn/ForgetPassword.vue"
      ),
    meta: { otherRequiresAuth: true },
  },
  {
    path: "/checkInbox",
    name: "CheckInbox",
    component: () =>
      import(
        /* webpackChunkName: "ckeckInbox" */ "../views/singIn/CkeckInbox.vue"
      ),
    meta: { otherRequiresAuth: true },
  },
  {
    path: "/change-password",
    name: "ResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ "../views/singIn/ResetPassword.vue"
      ),
    meta: { otherRequiresAuth: true },
  },
  {
    path: "/passHasReset",
    name: "PasswordHasReset",
    component: () =>
      import(
        /* webpackChunkName: "passHasReset" */ "../views/singIn/PasswordHasReset.vue"
      ),
    meta: { otherRequiresAuth: true },
  },
  // {
  //   path: "/map",
  //   name: "Map",
  //   component: () => import(/* webpackChunkName: "map" */ "../views/Map.vue"),
  //   meta: { requiresAuth: true },
  // },

  {
    path: "/map",
    name: "MapI",
    component: () => import(/* webpackChunkName: "map" */ "../views/MapI.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CompanyProfile.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/containers",
    name: "Containers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Containers.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-order",
    name: "Delivery-Order",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryOrder.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DOs",
    name: "DOs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dos.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/DOs/archived",
    name: "Archived",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Archived.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer-details/:id",
    name: "Customer-Details",
    component: () =>
      //import(/* webpackChunkName: "about" */ "../views/DeliveryOrderEdit.vue"),
      import(/* webpackChunkName: "about" */ "../views/CustomerDetails.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-order/:moveid/:id/:sid",
    name: "New DO",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryOrderEdit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-order/:id/:sid",
    name: "DO Edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DeliveryOrderEdit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/delivery-order/review/:id",
    name: "DO New Edit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewDeliveryOrderEdit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/drivers-details/:id/:sid",
    name: "DriversDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DriversDetails.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/drivers/:id",
    name: "Drivers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Drivers2.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/yards",
    name: "YardsI",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/YardI.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/chassis",
    name: "Chassis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChassisNew.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/reports",
    name: "Reports",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ReportsNew.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customers",
    name: "Customers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Customers2.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/container/:id",
    name: "Containers view",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Containersview.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/container/edit/:id",
    name: "ContainersEdit",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Containersedit.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/container/add/new",
    name: "CreateContainer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateContainer.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/containers/dispatch",
    name: "DispatchContainer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/DispatchContainer.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/containers/charges",
    name: "chargesContainer",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChargesContainer.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/customer-visibility/:customer_id/containers",
    name: "CustomerVisibilityContainers",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CustomerVisibility.vue"),
    meta: { customerVisibility : true, requiresAuth: false },
  },
  {
    path: "/do-form/:id",
    name: "CustomerForm",
    // component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/CustomerForm.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/form/vy/:id",
    name: "publicform",
    // component: Login
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Customerform2.vue"),
    meta: { requiresAuth: false },
  },
  //	{
  //	path: '/pos2/:id',
  //	name: 'podetails',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Podetails.vue'),
  //	meta: { requiresAuth: true },
  //},
  // {
  //   path: "/pos/item",
  //   name: "PoMobile",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "about" */ "../components/PosComponents/Mobile/PoMobile.vue"
  //     ),
  //   meta: { requiresAuth: true },
  // },
  //{
  //	path: '/products',
  //	name: 'Products',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Products.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/products/manage-categories',
  //	name: 'ManageCategories',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Categories.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/inventory',
  //	name: 'Inventory',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Inventory.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/warehouses/inventory',
  //	name: 'InventoryMobile',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../components/InventoryComponents/Mobile/InventoryMobile.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/suppliers',
  //	name: 'Suppliers',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Suppliers.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/reports',
  //	name: 'Reports',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Reports.vue'),
  //	meta: { requiresAuth: true },
  //},
  //{
  //	path: '/billing',
  //	name: 'Billing',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //	component: () => import( /* webpackChunkName: "about" */ '../views/Billing.vue'),
  //	meta: { requiresAuth: true },
  //},
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
  //   meta: { requiresAuth: true },
  // },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let data = sessionStorage.getItem("key");   
  if (to.name=='CustomerForm') {   
    if(data == null){     
      next({ name: 'publicform', params: { id: to.params.id } });
    }else{
      next();
    }
  }else if (to.name=='publicform') {    
    if(data == null){     
      next();
    }else{
      next({ name: 'CustomerForm', params: { id: to.params.id } });
    }
  }else
    
  //user_token
  if(to.matched.some((record) => record.meta.customerVisibility)){
    if (store.getters.getUserToken && store.getters.getExpiresAt) {
      next({
        name: "Dashboard",
      });
    } else {
      next();
    }

  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    //check token
    if (store.getters.getUserToken && store.getters.getExpiresAt) {
      //if token expired
      if (new Date(store.getters.getExpiresAt) <= new Date()) {
        // if token time is expired then try to refresh the token
        store
          .dispatch("refreshToken")
          .then(({ status }) => {
            if (status === 200 || status == 204) {
              console.log("refresh 200");
              next();
            } else {
              next({
                name: "Login",
              });
            }
          })
          .catch(() => {
            next({
              name: "Login",
            });
          });
      } else {
        next();
      }
    } else {
      next({
        name: "Login",
      });
    }
  } else {
    if (to.matched.some((record) => !record.meta.requiresAuth)) {
      if (store.getters.getUserToken && store.getters.getExpiresAt) {
        next({
          name: "Dashboard",
        });
      } else {
        next();
      }
    } else {
      //other routes
      if (store.getters.getUserToken && store.getters.getExpiresAt) {
        next({
          name: "Dashboard",
        });
      } else {
        // goes here if unkown and redirect to login
        next({
          name: "Login",
        });
      }
    }
  }
});

export default router;
