var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"logo"},[_c('router-link',{staticClass:"shipment-logo",attrs:{"to":"/shipments"}},[_c('img',{attrs:{"src":require("@/assets/images/logo.png"),"alt":""}})])],1),_c('ul',{staticClass:"links sidebarMenu"},[_c('li',[_c('router-link',{class:{
            'active-link':
              _vm.$store.state.page.currentPage == 'containers' ||
              _vm.$store.state.page.currentPage == 'delivery-order',
          },attrs:{"to":"/containers"}},[_c('i',{staticClass:"ic-truckings"}),_vm._v(" Containers ")])],1),_c('li',[_c('router-link',{class:{
            'active-link':
              _vm.$store.state.page.currentPage == 'Dos' ||
              _vm.$store.state.page.currentPage == 'Dos',
          },attrs:{"to":"/Dos"}},[_c('i',{staticClass:"ic-truckings"}),_vm._v(" Dos ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'drivers',
          },attrs:{"to":"/drivers/01"}},[_c('i',{staticClass:"ic-drivers"}),_vm._v(" Drivers ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'yards',
          },attrs:{"to":"/yards"}},[_c('i',{staticClass:"ic-yards"}),_vm._v(" Yards ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'customers',
          },attrs:{"to":"/customers"}},[_c('i',{staticClass:"ic-suppliers"}),_vm._v(" Customer ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'chassis',
          },attrs:{"to":"/chassis"}},[_c('i',{staticClass:"ic-chassis"}),_vm._v(" Chassis ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'reports',
          },attrs:{"to":"/reports"}},[_c('i',{staticClass:"ic-reports mr-3"}),_vm._v(" Reports ")])],1),_c('li',[_c('router-link',{class:{
            'active-link': _vm.$store.state.page.currentPage == 'map',
          },attrs:{"to":"/map"}},[_c('i',{staticClass:"ic-map-pin"}),_vm._v(" Map ")])],1),(_vm.accountingModuleEnabled)?_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.accountingMenuShow),callback:function ($$v) {_vm.accountingMenuShow=$$v},expression:"accountingMenuShow"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"settings-menu-group"},[_c('img',{attrs:{"src":require("@/assets/images/transfer.svg"),"alt":""}}),_vm._v(" Accounting ")])]),_c('v-expansion-panel-content',[_c('v-list-item-group',{staticClass:"pa-0",attrs:{"two-line":""}},[_vm._l((_vm.accountingMenuItems),function(item,i){return [(item.nonQBO ? _vm.isQBOEnabled === 0 : true)?_c('v-list-item',{key:("accounting-menu-" + i),class:{ 'active-link': _vm.getCurrentPageIndex === i + 1 && _vm.$route.path === item.path },nativeOn:{"click":function($event){return _vm.onClickAccounting(i + 1)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":item.path}},[_c('div',{staticClass:"icon-separator"}),_c('span',[_vm._v(_vm._s(item.title))])])],1)],1):_vm._e()]})],2)],1)],1)],1):_vm._e(),_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.settingsMenuShow),callback:function ($$v) {_vm.settingsMenuShow=$$v},expression:"settingsMenuShow"}},[_c('v-expansion-panel',{model:{value:(_vm.settingsMenuShow),callback:function ($$v) {_vm.settingsMenuShow=$$v},expression:"settingsMenuShow"}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"settings-menu-group"},[_c('img',{attrs:{"src":require("@/assets/images/settings.svg"),"alt":""}}),_vm._v(" Settings ")])]),_c('v-expansion-panel-content',[_c('v-list-item-group',{staticClass:"pa-0",attrs:{"two-line":""}},[_c('v-list-item',{class:_vm.$router.currentRoute.name === 'Settings' &&
                  _vm.$store.state.page.currentSettingsTab == 0
                    ? 'active-link'
                    : '',nativeOn:{"click":function($event){return _vm.onClickSettings(0)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":"/settings?tab=company-profile"}},[_c('div',{staticClass:"icon-separator"}),_c('span',[_vm._v("Company Profile")])])],1)],1),_c('v-list-item',{class:_vm.$router.currentRoute.name === 'Settings' &&
                  _vm.$store.state.page.currentSettingsTab == 1
                    ? 'active-link'
                    : '',on:{"click":function($event){return _vm.onClickSettings(1)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":"/settings?tab=notifications"}},[_c('div',{staticClass:"icon-separator"}),_vm._v(" Notifications ")])],1)],1),_c('v-list-item',{class:_vm.$router.currentRoute.name === 'Settings' &&
                  _vm.$store.state.page.currentSettingsTab == 2
                    ? 'active-link'
                    : '',on:{"click":function($event){return _vm.onClickSettings(2)}}},[_c('v-list-item-title',[_c('router-link',{attrs:{"to":"/settings?tab=integrations"}},[_c('div',{staticClass:"icon-separator"}),_vm._v(" Integrations ")])],1)],1)],1)],1)],1)],1),(_vm.isMobile)?_c('div',{staticStyle:{"height":"2px","background-color":"#039ddc","margin":"12px"}}):_vm._e(),(_vm.isMobile)?_c('li',{staticStyle:{"padding":"0 16px","color":"#e1ecf0 !important","display":"flex","align-items":"center","cursor":"pointer"}},[_c('v-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":"35"}},[_c('v-icon',{staticClass:"mr-2 account",attrs:{"small":""}},[_vm._v(" mdi-account ")])],1),_vm._v(" "+_vm._s(_vm.getUser ? JSON.parse(_vm.getUser).name : "")+" ")],1):_vm._e(),(_vm.isMobile)?_c('li',[_c('v-btn',{staticClass:"btn-logout-mobile",on:{"click":_vm.userLogout}},[_c('img',{attrs:{"src":require("@/assets/images/sign-out.png"),"alt":""}}),_vm._v(" Sign Out ")])],1):_vm._e()],1),_c('p',{staticClass:"footer"},[_vm._v("© "+_vm._s(_vm.getYear())+" Shifl. All rights reserved.")])])}
var staticRenderFns = []

export { render, staticRenderFns }