import mapboxgl from "mapbox-gl";
import routing from "./routing";
import moment from "moment";
import containerTracking from "./containerTracking";

const state = {
  currentPage: 1,
  mapInstance: null,
  prevDriverMarkers: null,
  prevYardMarkers: null,
  prevPortMarkers: null,
  prevContainerMarkers: null,
  loadingStatus: false,
  dataForPolyline: [],
};
const getters = {
  getCurrentPage: (state) => state.currentPage,
  getMapInstance: (state) => state.mapInstance,
  getPrevYardMarkers: (state) => state.prevYardMarkers,
  getPrevDriverMarkers: (state) => state.prevDriverMarkers,
  getPrevContainerMarkers: (state) => state.prevContainerMarkers,
  getLoadingStatus: (state) => state.loadingStatus,
  getDataForPloyline: (state) => state.dataForPolyline,
};

const actions = {
  drawMapAndIcons(context) {
    const ports = context.rootGetters["common/getPorts"];
    if (ports.length == 0) {
      context.dispatch("common/fetchTerminals", null, {
        root: true,
      });
    }
    context.dispatch("loadMapFromVuex").then(() => {
      context.dispatch("getResouces");
    });
  },
  async loadMapFromVuex({ commit, getters }) {
    return new Promise((resolve) => {
      const mapInstance = getters.getMapInstance;
      if (mapInstance) {
        return;
      }
      mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_PUBLIC_KEY;
      const map = new mapboxgl.Map({
        container: "map", // container ID
        style: "mapbox://styles/mapbox/streets-v11", // style URL
        center: [72.240071, 34.509714],
        zoom: 4, // starting zoom,
        attributionControl: false,
      });
      map.on("style.load", () => {
        map.setFog({}); // Set the default atmosphere style
      });
      map.addControl(new mapboxgl.NavigationControl());
      commit("SET_MAP_INSTANCE", map);
      if (map) {
        resolve("done");
      }
    });
  },
  getResouces(context) {
    const drivers = context.rootGetters["drivers/getDrivers"];
    const yards = context.rootGetters["yards/getYards"];
    const ports = context.rootGetters["common/getPorts"];

    if (yards.length > 0) {
      context.dispatch("addYardsMarker", yards);
    }
    if (ports.length > 0) {
      context.dispatch("addPortsMarker", ports);
    }
    if (drivers.length > 0) {
      context.dispatch("addDriverMarkers", drivers);
    }

    if (drivers.length == 0) {
      context
        .dispatch("drivers/fetchDriversPromise", null, {
          root: true,
        })
        .then(() => {
          const freshDriversData = context.rootGetters["drivers/getDrivers"];
          context.dispatch("addDriverMarkers", freshDriversData);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (yards.length == 0) {
      const yards = context.dispatch("yards/getYardsFromApiPromise", null, {
        root: true,
      });
      yards
        .then(() => {
          const freshYardsData = context.rootGetters["yards/getYards"];
          context.dispatch("addYardsMarker", freshYardsData);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (ports.length == 0) {
      context
        .dispatch("common/fetchTerminals", null, { root: true })
        .then(() => {
          const portsFresh = context.rootGetters["common/getPorts"];
          context.dispatch("addPortsMarker", portsFresh);
        });
    }
    context
      .dispatch("containerTracking/fetchContainerLocationFromApi")
      .then(() => {
        context.dispatch("containerTracking/drawContainerIcons");
      });
  },
  addDriverMarkers({ getters, commit, dispatch }, data) {
    if (getters.getMapInstance !== null) {
      var markers = [
        {
          type: String,
          driverId: Number,
          marker: Object,
        },
      ];
      data.forEach((element) => {
        if (
          element.coordinates !== null &&
          element.coordinates.long !== null &&
          element.coordinates.lat !== null
        ) {
          let el = document.createElement("img");
          if (moment().isAfter(element.coordinates.updated_at, "day")) {
            el.src = require("@/assets/images/non-Active.png");
          } else {
            el.src = require("@/assets/images/driver.png");
          }
          el.height = 48;
          el.width = 40;
          el.style.borderRadius = 25;
          var tempMarker = new mapboxgl.Marker(el)
            .setLngLat([element.coordinates.long, element.coordinates.lat])
            .setPopup(
              new mapboxgl.Popup({
                className: "custom-popup",
                closeButton: false,
              }).setHTML(`
                <div class="twoRowHeight">
                    <p class="headingRowPopup">DRIVER ID #${
                      element.coordinates.id
                    } </p>
                    <p class="subRow">${element.name}</p>
                    <p style="font-weight:600">Last Tracked: ${moment(
                      element.coordinates.updated_at
                    ).fromNow()}</p>
                  </div>`)
            )
            .addTo(getters.getMapInstance);
          const item = {
            type: "driverMarker",
            driverId: element.coordinates.id
              ? element.coordinates.id
              : element.id,
            marker: tempMarker,
          };
          markers.push(item);
        }
      });
      commit("SET_PREV_DRIVER_MARKERS", markers);
      dispatch("connectToWebSocekts");
    }
  },
  // addDriverMarkersHavingRandom({ getters, commit, dispatch }, data) {
  //   if (getters.getMapInstance !== null) {
  //     var markers = [
  //       {
  //         type: String,
  //         driverId: Number,
  //         marker: Object,
  //       },
  //     ];
  //     data.forEach((element) => {
  //       if (element.lat !== null) {
  //         let el = document.createElement("img");
  //         const randomActiveStatus = Math.floor(Math.random() * 2);
  //         el.src =
  //           randomActiveStatus === 1
  //             ? require("@/assets/images/activeDriver.png")
  //             : require("@/assets/images/driver.png");
  //         el.height = 48;
  //         el.width = 40;
  //         el.style.borderRadius = 25;
  //         var tempMarker = new mapboxgl.Marker(el)
  //           .setLngLat([element.long, element.lat])
  //           .setPopup(
  //             new mapboxgl.Popup({
  //               className: "custom-popup",
  //               closeButton: false,
  //             }).setHTML(
  //               randomActiveStatus === 1
  //                 ? `<div class="activeShipment">
  //                     <p class="headingRowPopup">DRIVER ID #${element.id} </p>
  //                     <div class="custom-icon">
  //                         <div style="background-color:red;height:10px;width:10px;border-radius:5px;margin-top:5px;">
  //                         </div>
  //                        <p style="margin-left:5px;">${element.name}</p>
  //                     </div>

  //                     <div class="activeDriverShip">
  //                       <i class="fa fa-truck custom-size" aria-hidden="true"></i>
  //                       <p class="infoText">Heading to the destination</p>
  //                     </div>
  //                   </div>
  //                   `
  //                 : `<div class="twoRowHeight">
  //                     <p class="headingRowPopup">DRIVER ID #${element.id} </p><p class="subRow">${element.name}</p>
  //                   </div>
  //                   `
  //             )
  //           )
  //           .addTo(getters.getMapInstance);
  //       }
  //       const item = {
  //         type: "driverMarker",
  //         driverId: element.id,
  //         marker: tempMarker,
  //       };
  //       markers.push(item);
  //     });
  //     commit("SET_PREV_DRIVER_MARKERS", markers);
  //     dispatch("connectToWebSocekts");
  //   }
  // },
  addYardsMarker({ getters, commit }, data) {
    var markers = [
      {
        type: String,
        yardId: Number,
        marker: Object,
      },
    ];
    data.forEach((element) => {
      if (element.longitude !== null && element.latitude !== null) {
        let el = document.createElement("img");
        el.src = require("@/assets/images/yard.png");
        el.height = 48;
        el.width = 40;
        el.style.borderRadius = 25;
        var tempMarker = new mapboxgl.Marker(el)
          .setLngLat([element.longitude, element.latitude])
          .setPopup(
            new mapboxgl.Popup({
              className: "custom-popup",
              closeButton: false,
            }).setHTML(`
              <div class="twoRowHeight">
                <p class="headingRowPopup">Location :  ${element.map_location}</p>
                <p class="subRow">Yard Name : ${element.name}</p>
              </div>
            `)
          )
          .addTo(getters.getMapInstance);
      }
      const item = {
        type: "yard",
        yardId: element.id,
        marker: tempMarker,
      };
      markers.push(item);
    });
    commit("SET_PREV_YARD_MARKERS", markers);
  },
  addPortsMarker({ getters, commit }, data) {
    var markers = [
      {
        type: String,
        yardId: Number,
        marker: Object,
      },
    ];
    data.forEach((element) => {
      if (
        element.longitude !== null &&
        element.latitude !== "null" &&
        element.latitude !== null &&
        !isNaN(element.latitude)
      ) {
        let el = document.createElement("img");
        el.src = require("@/assets/images/port.png");
        el.height = 48;
        el.width = 40;
        el.style.borderRadius = 25;
        var tempMarker = new mapboxgl.Marker(el)
          .setLngLat([element.longitude, element.latitude])
          .setPopup(
            new mapboxgl.Popup({
              className: "custom-popup",
              closeButton: false,
            }).setHTML(`
              <div class="twoRowHeight">
                <p class="headingRowPopup">Port Name :  ${element.name}</p>
                <p class="subRow">Location : ${element.map_location}</p>
              </div>
            `)
          )
          .addTo(getters.getMapInstance);
        const item = {
          type: "port",
          portId: element.id,
          marker: tempMarker,
        };
        markers.push(item);
      }
    });
    commit("SET_PREV_YARD_MARKERS", markers);
  },

  setDriverMarker(context, updatedDriverLocation) {
    if (!context.getters.getPrevDriverMarkers) return;
    context.getters.getPrevDriverMarkers.forEach((element) => {
      if (element.driverId == updatedDriverLocation.id) {
        element.marker
          .setLngLat([updatedDriverLocation.long, updatedDriverLocation.lat])
          .setPopup(
            new mapboxgl.Popup({
              className: "custom-popup",
              closeButton: false,
            }).setHTML(
              `<div class="twoRowHeight">
                    <p class="headingRowPopup">DRIVER ID #${
                      updatedDriverLocation.id
                    } </p><p class="subRow">${updatedDriverLocation.name}</p>
                    <p style="font-weight:700">Last Tracked :</p><p class="subRow">${moment(
                      updatedDriverLocation.updated_at
                    ).fromNow()}</p>
                  </div>
                  `
            )
          )
          .addTo(context.getters.getMapInstance);
      }
    });
  },
  connectToWebSocekts(context) {
    window.Echo.private(
      `trucker.${context.rootGetters["getTruckerId"]}`
    ).listen("DriverTracking", (e) => {
      // console.log(e);
      context.dispatch("setDriverMarker", e.user);
    });
  },

  clearState({ commit }) {
    commit("CLEAR_STATE", null);
    console.log("state cleared");
  },
};

const mutations = {
  SET_MAP_INSTANCE: (state, payload) => {
    state.mapInstance = payload;
  },
  SET_PREV_DRIVER_MARKERS: (state, payload) => {
    state.prevDriverMarkers = payload;
  },
  SET_PREV_YARD_MARKERS: (state, payload) => {
    state.prevYardMarkers = payload;
  },
  SET_PREV_CONTAINER_MARKERS: (state, payload) => {
    state.prevContainerMarkers = payload;
  },
  SET_LOADING: (state, payload) => {
    state.loading = payload;
  },
  CLEAR_STATE: (state) => {
    state.mapInstance = null;
    state.prevDriverMarkers = null;
    state.prevYardMarkers = null;
  },
};

const modules = {
  routing,
  containerTracking,
};

export default {
  namespaced: true,
  modules,
  state,
  mutations,
  actions,
  getters,
};
