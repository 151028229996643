import axios from "axios";
const APIBaseUrl = process.env.VUE_APP_PO_URL;

const state = {
  companyProfile: "",
  loading: false,
  textAddressHover: false,
  headers: [
    {
      text: "User",
      value: "name",
      align: "start",
      width: "15%",
      fixed: true,
    },
    {
      text: "Email Address",
      value: "email",
      align: "start",
      width: "20%",
      fixed: true,
    },
    // { text: "User Group", value: "userGroup" },
    {
      text: "User Role",
      value: "userRole",
      align: "start",
      width: "25%",
      fixed: true,
    },
    {
      text: "Date Added",
      value: "created_at",
      align: "start",
      width: "20%",
      fixed: true,
    },
    {
      text: "Last Activity at",
      value: "updated_at",
      align: "start",
      width: "25%",
      fixed: true,
    },
    // {
    //   text: "",
    //   align: "end",
    //   value: "actions",
    //   sortable: false,
    //   width: "20%",
    //   fixed: true,
    // },
  ],
  headersUserGroup: [
    {
      text: "User Group",
      value: "userGroup",
      align: "start",
      width: "20%",
      fixed: true,
    },
    {
      text: "No of Users",
      value: "noOfUsers",
      align: "start",
      width: "20%",
      fixed: true,
    },
    {
      text: "Users",
      value: "users",
      align: "start",
      width: "20%",
      fixed: true,
    },
    {
      text: "Permission",
      value: "permission",
      align: "start",
      width: "20%",
      fixed: true,
    },
    {
      text: "",
      align: "end",
      value: "actions",
      sortable: false,
      width: "20%",
      fixed: true,
    },
  ],
  userWithRoleAssigned: [],
  showStatic: true,
  companyProfileImage: null,
  userMoreInfoHover: false,
};
const getters = {
  getLoading: (state) => state.loading,
  getShowStatic: (state) => state.showStatic,
  getCompanyProfileImage: (state) => state.companyProfileImage,
  getCompanyProfile: (state) => state.companyProfile,
  getTextAddressHover: (state) => state.textAddressHover,
  getUserMoreInfoHover: (state) => state.userMoreInfoHover,
  getHeaders: (state) => state.headers,
  getHeadersUserGroup: (state) => state.headersUserGroup,
  getUserWithRoleAssigned: (state) => state.userWithRoleAssigned,
};
const actions = {
  getCompanyProfileAsync: async (context) => {
    await axios
      .get(`${APIBaseUrl}/get-company-information`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            const url = APIBaseUrl + "/storage/" + res.data.companyProfile.logo;
            const newformat = {
              ...res.data.companyProfile,
              ...res.data.companyKey,
              imageUrlProfile: url,
            };
            context.commit("SET_COMPANY_PROFILE", newformat);
          }
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  getCompanyUsersFromApi: async (context) => {
    await axios
      .get(`${APIBaseUrl}/users`)
      .then((res) => {
        if (res.status === 200) {
          if (res.data) {
            context.commit("SET_USER_WITH_ROLE_ASSIGNED", res.data);
          }
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  updateCompanyApiRequest: async (context, payload) => {
    context.commit("SET_LOADING_STATUS", true);
    await axios
      .put(`${APIBaseUrl}/update-company-profile/${payload.id}`, payload)
      .then(() => {
        context.dispatch("getCompanyProfileAsync");
        context.commit("SET_LOADING_STATUS", false);
      })
      .catch((error) => {
        console.log(error);
        context.commit("SET_LOADING_STATUS", false);
      });
  },
};
const mutations = {
  SET_COMPANY_PROFILE: (state, payload) => {
    state.companyProfile = payload;
  },
  SET_USER_WITH_ROLE_ASSIGNED: (state, payload) => {
    state.userWithRoleAssigned = payload.usersWithRole;
  },
  SET_TEXT_ADRESS_HOVER: (state, payload) => {
    state.textAddressHover = payload;
  },
  SET_USER_MORE_INFO_HOVER: (state, payload) => {
    state.userMoreInfoHover = payload;
  },
  SET_LOADING_STATUS: (state, payload) => {
    state.loading = payload;
  },
  SET_LOADING_STATUS_FOR_ROLES: (state, payload) => {
    state.loading = payload;
  },
  SET_COMPANY_LOGO: (state, payload) => {
    state.showStatic = false;
    state.companyProfileImage = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
