export const accountingMenuItems = [
    {
      title: 'Items',
      path: '/accounting/items'
    },
    {
      title: 'Invoices',
      path: '/accounting/invoices'
    },
    {
      title: 'Customers',
      path: '/accounting/customers'
    },
    {
      title: 'Bills',
      path: '/accounting/bills'
    },
    {
      title: 'Vendors',
      path: '/accounting/vendors'
    },
    {
      title: 'Transactions',
      path: '/accounting/transactions',
      nonQBO: true
    },
    {
      title: 'Manual Journal Entry',
      path: '/accounting/journal-entries',
      nonQBO: true
    },
    {
      title: 'Chart of Accounts',
      path: '/accounting/chart-of-accounts',
      nonQBO: true
    },
    {
      title: 'Accounts',
      path: '/accounting/accounts',
      nonQBO: true
    },
    {
      title: 'Reports',
      path: '/accounting/reports',
      nonQBO: true
    },
    {
      title: 'Reconciliations',
      path: '/accounting/reconciliations'
    },
    {
      title: 'Settings',
      path: '/accounting/settings'
    },
  ];
