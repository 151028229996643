export const accountingMenuItems = [
  {
    title: 'Items',
    path: '/accounting/items'
  },
  {
    title: 'Invoices',
    path: '/accounting/invoices'
  },
  {
    title: 'Customers',
    path: '/accounting/customers'
  },
  {
    title: 'Bills',
    path: '/accounting/bills'
  },
  {
    title: 'Vendors',
    path: '/accounting/vendors'
  },
  {
    title: 'Transactions',
    path: '/accounting/transactions',
    nonQBO: true
  },
  {
    title: 'Manual Journal Entry',
    path: '/accounting/journal-entries',
    nonQBO: true
  },
  {
    title: 'Chart of Accounts',
    path: '/accounting/chart-of-accounts',
    nonQBO: true
  },
  {
    title: 'Accounts',
    path: '/accounting/accounts',
    nonQBO: true
  },
  {
    title: 'Reports',
    path: '/accounting/reports',
    nonQBO: true
  },
  {
    title: 'Reconciliations',
    path: '/accounting/reconciliations'
  },
  {
    title: 'Settings',
    path: '/accounting/settings'
  },
];

const routes = [
  {
    path: '/accounting',
    name: 'Accounting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import(/* webpackChunkName: "accounting-accounts" */ '../views/Accounts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bills',
        name: 'Bills',
        component: () => import(/* webpackChunkName: "accounting-bills" */ '../views/Bills.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'bills/:id',
        name: 'BillDetails',
        component: () => import(/* webpackChunkName: "accounting-bill-details" */ '../views/BillDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'customers',
        name: 'Customers',
        component: () => import(/* webpackChunkName: "accounting-customers" */ '../views/Customers.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'customers/:id',
        name: 'CustomerDetails',
        component: () => import(/* webpackChunkName: "accounting-customer-details" */ '../views/CustomerDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'items',
        name: 'ItemsAndAccountBased',
        component: () => import(/* webpackChunkName: "accounting-itemsaccountbased" */ '../views/ItemsAndAccountBased.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invoices',
        name: 'Invoices',
        component: () => import(/* webpackChunkName: "accounting-invoices" */ '../views/Invoices.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'invoices/:id',
        name: 'InvoiceDetails',
        component: () => import(/* webpackChunkName: "accounting-invoice-details" */ '../views/InvoiceDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: () => import(/* webpackChunkName: "accounting-accounts" */ '../views/Vendors.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'vendors/:id',
        name: 'AccountingVendorDetails',
        component: () => import(/* webpackChunkName: "accounting-vendors" */ '../views/VendorDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'chart-of-accounts',
        name: 'ChartOfAccounts',
        component: () => import(/* webpackChunkName: "accounting-coa" */ '../views/ChartAccounts.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'transactions',
        name: 'AccountingTransactions',
        component: () => import(/* webpackChunkName: "accounting-transactions" */ '../views/Transactions.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'transactions/:id',
        name: 'AccountingTransactionDetails',
        component: () => import(/* webpackChunkName: "accounting-transation-details" */ '../views/TransactionDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'settings',
        name: 'AccountingSettings',
        component: () => import(/* webpackChunkName: "accounting-settings" */ '../views/Settings.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'quickbooks/token',
        name: 'QuickbooksToken',
        component: () => import(/* webpackChunkName: "accounting-qbotoken" */ '../views/QuickbooksToken.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports',
        name: 'AccountingReports',
        component: () => import(/* webpackChunkName: "accounting-reports" */ '../views/Reports.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/:id',
        name: 'AccountingReportDetails',
        component: () => import(/* webpackChunkName: "accounting-accounts" */ '../views/ReportDetails.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'journal-entries',
        name: 'AccountingJournalsEntries',
        component: () => import(/* webpackChunkName: "accounting-journalentries" */ '../views/ManualJournalEntry.vue'),
        meta: {
          requiresAuth: true
        }
      },

      // Reports
      {
        path: 'reports/general-ledger/:id',
        name: 'AccountingGeneralLedgerReports',
        component: () => import(/* webpackChunkName: "accounting-generalledger" */ '../views/Reports/GeneralLedger.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/profit-and-loss/:id',
        name: 'AccountingProfitAndLossReports',
        component: () => import(/* webpackChunkName: "accounting-profitandloss" */ '../views/Reports/ProfitAndLoss.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/trial-balance/:id',
        name: 'AccountingTrialBalanceReports',
        component: () => import(/* webpackChunkName: "accounting-trialbalance" */ '../views/Reports/TrialBalance.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/balance-sheet/:id',
        name: 'AccountingBalanceSheetReports',
        component: () => import(/* webpackChunkName: "accounting-balancesheet" */ '../views/Reports/BalanceSheet.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/income-summary/:id',
        name: 'AccountingIncomeSummaryReports',
        component: () => import(/* webpackChunkName: "accounting-income-summary" */ '../views/Reports/IncomeSummary.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/expense-summary/:id',
        name: 'AccountingExpenseSummaryReports',
        component: () => import(/* webpackChunkName: "accounting-expense-summary" */ '../views/Reports/ExpenseSummary.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reports/journal-entries/:id',
        name: 'AccountingJournalEntriesReports',
        component: () => import(/* webpackChunkName: "accounting-journal-entries-reports" */ '../views/Reports/JournalEntries.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'reconciliations',
        name: 'AccountingReconciliations',
        component: () => import(/* webpackChunkName: "accounting-reconciliations" */ '../views/Reconciliations.vue'),
        meta: {
          requiresAuth: true
        }
      },
    ]
  }
];

export default router => {
  router.addRoutes(routes);
};
