import axios from "axios";

const state = {
  yards: [],
  pageCount: null,
  currentPage: null,
  yardsLoading: false,
  createYard: null,
  updateYard: null,
  createYardLoading: false,
  updateYardLoading: false,
  deleteYardLoading: false,
  yardSaveAddLoading: false,
  trucker_id: null,
  alert_box: false,
  alert_box_text: "",
  alert_box_type: "success",
  details: [],
};
const getters = {
  getYards: (state) => state.yards,
  getpageCount: (state) => state.pageCount,
  getCurrentPage: (state) => state.currentPage,
  getYardLoading: (state) => state.yardsLoading,
  getCreateYards: (state) => state.createYard,
  getUpdateYards: (state) => state.updateYard,
  getCreateYardLoading: (state) => state.createYardLoading,
  getUpdateYardsLoading: (state) => state.updateYardLoading,
  getDeleteYardsLoading: (state) => state.deleteYardLoading,
  getYardsSaveAddLoading: (state) => state.YardSaveAddLoading,
  getTruckerId: (state) => state.trucker_id,
  getalert_box: (state) => state.alert_box,
  get_alert_box_text: (state) => state.alert_box_text,
  get_alert_type: (state) => state.alert_box_type,
  getdetails: (state) => state.details,
};

const APIBaseUrl = process.env.VUE_APP_PO_URL;

const actions = {
  changeAlertBoxStatus: ({ commit }, payload) => {
    commit("CHANGE_STATUS_OF_ALERT", payload);
  },
  getYardsFromApi: async ({ commit }) => {
    commit("SET_YARDS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getyards`)
      .then((res) => {
        commit("SET_YARDS_LOADING", false);
        commit("SET_YARDS", res.data);
      })
      .catch((err) => {
        //commit("SET_ALERT_BOX_TEXT", err);
        if (typeof err.error !== "undefined") {
          return Promise.reject(err.error);
        } else {
          return Promise.reject(err.message);
        }
      });
  },
  getYardsFromApiPromise: async ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${APIBaseUrl}/getyards`)
        .then((res) => {
          commit("SET_YARDS", res.data);
          resolve("done");
        })
        .catch((e) => {
          reject("error occured", e);
        });
    });
  },
  getOthesPageYards: async ({ commit }, payload) => {
    commit("SET_YARDS_LOADING", true);
    await axios
      .get(`${APIBaseUrl}/getyards?page=${payload}`)
      .then((res) => {
        commit("SET_YARDS_LOADING", false);
        commit("SET_YARDS", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  addNewYard: async ({ commit }, payload) => {
    commit("SET_CREATE_YARD_LOADING", true);
    const newYardObj = {
      trucker_id: state.trucker_id,
      ...payload,
    };
    await axios
      .post(`${APIBaseUrl}/createYard`, newYardObj)
      .then((res) => {
        commit("SET_CREATE_YARD_LOADING", false);
        commit("ADD_NEW_YARD", res.data);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
  editYardItem: async ({ commit }, payload) => {
    commit("SET_UPDATE_YARD_LOADING", true);
    let editYardItems = {
      ...payload,
    };
    await axios
      .put(`${APIBaseUrl}/updateYard/${payload.id}`, editYardItems)
      .then((res) => {
        const currentState = state.yards;
        const newEditArray = currentState.filter((item) => {
          return item.id !== payload.id;
        });
        newEditArray.unshift(editYardItems);
        commit("SET_UPDATE_YARDS", {
          newEditArray: newEditArray,
          data: res.data,
          olditem: currentState,
        });

        commit("SET_UPDATE_YARD_LOADING", false);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
  deleteYardItem: async ({ commit }, payload) => {
    commit("SET_DELETE_YARD_LOADING", true);
    await axios
      .delete(`${APIBaseUrl}/deleteYard/${payload.id}`)
      .then((res) => {
        commit("REMOVE_YARD_ITEM", res.data);
        commit("SET_DELETE_YARD_LOADING", false);
      })
      .catch((error) => {
        commit("SET_ALERT_BOX_TEXT", error);
      });
  },
};

const mutations = {
  CHANGE_STATUS_OF_ALERT: (state, payload) => {
    state.alert_box = payload;
  },
  SET_ALERT_BOX_TEXT: (state, payload) => {
    state.yardsLoading = false;
    state.alert_box_type = "error";
    state.alert_box_text = payload;
    state.alert_box = true;
  },
  REMOVE_YARD_ITEM: (state, payload) => {
    state.yards = [];
    state.yards = payload.yards.data;
    state.pageCount = payload.yards.last_page;
    state.current_page = payload.yards.current_page;
    state.trucker_id = payload.trucker_id;
    state.updateYardLoading = false;
    state.alert_box = true;
    state.alert_box_text = "Yard has been deleted";
    state.alert_box_type = "delete";
  },
  ADD_NEW_YARD: (state, payload) => {
    state.yards = [];
    state.yards = payload.yards.data;
    state.pageCount = payload.yards.last_page;
    state.current_page = payload.yards.current_page;
    state.trucker_id = payload.trucker_id;
    state.alert_box = true;
    state.alert_box_text = "Yard has been created";
    state.alert_box_type = "success";
    state.details = { status: payload.status, msg: payload.msg };
  },
  SET_YARDS: (state, payload) => {
    state.yards = payload.yards.data;
    state.pageCount = payload.yards.last_page;
    state.current_page = payload.yards.current_page;
    state.trucker_id = payload.trucker_id;
  },
  SET_YARDS_LOADING: (state, payload) => {
    state.yardsLoading = payload;
  },
  SET_CREATE_YARDS: (state, payload) => {
    state.createYard = payload;
  },
  SET_CREATE_YARD_LOADING: (state, payload) => {
    state.createYardLoading = payload;
  },
  SET_YARDS_SAVE_ADD_LOADING: (state, payload) => {
    state.yardSaveAddLoading = payload;
  },
  SET_UPDATE_YARDS: (state, payload) => {
    state.yards = [];
    state.alert_box = true;
    state.alert_box_text = "Yard has been updated";
    state.alert_box_type = "success";
    state.yards = payload.data.status ? payload.newEditArray : payload.olditem;
    state.details = {
      status: payload.data.status,
      msg: payload.data.message,
    };
  },
  SET_UPDATE_YARD_LOADING: (state, payload) => {
    state.updateYardLoading = payload;
  },
  SET_DELETE_YARD_LOADING: (state, payload) => {
    state.deleteYardLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
