import axios from "axios"

const state = {
    customers: [],
    customersLoading: false,
	createCustomer: null,
	updateCustomer: null,
	updateCustomerLoading: false,
	createCustomerLoading: false,
	customerSaveAddLoading: false,
	allcustomerDetails: [],
	editViewData: [],
	managereportschedule:[],
	getlastsentreport:null,
	getlastsentreportcount:0,
	CustID:0,
	detailsData:[],
	customersListsLoading: false
}

const getters = {
	getcustID: state => state.CustID,
	getlastsentreportcount: state=> state.getlastsentreportcount,
	getLastSentReport: state=> state.getlastsentreport,
	getmanagereportschedule: state =>state.managereportschedule,
	getViewDataCustomers: state => state.editViewData,
    getCustomers: state => state.customers,
    getCustomerLoading: state => state.customersLoading,
	getCreateCustomers: state => state.createCustomer,
    getCreateCustomerLoading: state => state.createCustomerLoading,
	getUpdateCustomers: state => state.updateCustomer,
    getUpdateCustomersLoading: state => state.updateCustomerLoading,
	getCustomersSaveAddLoading: state => state.customerSaveAddLoading,
	getcustomerdetails: state => state.allcustomerDetails,
	getdetailsdata: state => state.detailsData,
	getCustomersListsLoading: state => state.customersListsLoading,
}

const APIBaseUrl = process.env.VUE_APP_PO_URL
const actions = {
	passData: async ({commit},payload) => {
		commit("EDITVIEWDATA", payload)		
	},		
	fetchLastSentReport: async ({commit},payload) => {
		//commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/getlastreport/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {					
					//commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_LAST_SENT_REPORT", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	getManageReportSetting: async ({commit},payload) => {
		//commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/getManageReportSetting/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					//commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_FETCH_MANAGE_REPORT_SCHEDULE", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},

	fetchCustomerDetails: async ({commit},payload) => {
		commit("SET_CUSTOMERS_LOADING", true)
		await axios.get(`${APIBaseUrl}/customer/details/`+payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					
					commit("SET_CUSTOMERS_LOADING", false)
					commit("SET_FETCH_DETAILS_CUSTOMERS", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
    fetchCustomers: async ({
		commit
	}, ) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)

		await axios.get(`${APIBaseUrl}/customersList`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_GET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchCustomersv2: async ({
		commit
	}, page) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)
		commit("SET_CUSTOMERS", [])
		page = page !== undefined ? page : 1
		
		await axios.get(`${APIBaseUrl}/v2/customersList?page=${page}`)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data.data)
				}
			}
		})
		.catch(err => {
			commit("SET_GET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	fetchCustomersSearched: async ({
		commit
	}, payload) => {
		commit("SET_GET_CUSTOMERS_LOADING", true)
		commit("SET_CUSTOMERS", [])

		await axios(payload)
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					commit("SET_GET_CUSTOMERS_LOADING", false)
					commit("SET_CUSTOMERS", res.data.data)
				}
			}
		})
		.catch(err => {
			commit("SET_GET_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	},
	setSearchedCustomersLoading({ commit } , payload) {
		commit("SET_GET_CUSTOMERS_LOADING", payload)
	},
    setCustomersSearchedVal({ commit }, payload) {
		commit("SET_CUSTOMERS", payload)
	},
	createCustomers: async ({ commit }, payload ) => {		
        //commit("SET_CREATE_CUSTOMERS_LOADING", true)
		await axios.post(`${APIBaseUrl}/createCustomer`, {
			name: payload.name,
			email: payload.email,
			pnumber: payload.pnumber,		
		})
		.then(res => {
			if (res.status === 200) {
				if (res.data) {
					//commit("SET_CREATE_CUSTOMERS_LOADING", false)
					commit("SET_CREATE_CUSTOMERS", res.data)
				}
			}
		})
		.catch(err => {
			commit("SET_CREATE_CUSTOMERS_LOADING", false)
			return Promise.reject(err)
		})
	}
}

const mutations = {	
	EDITVIEWDATA: (state,payload) => {
		state.detailsData = payload
	},	
	SET_LAST_SENT_REPORT: (state, payload) => {		
		state.getlastsentreport = payload.data
		state.getlastsentreportcount = payload.count
		state.CustID = payload.customer_id
	},
	SET_FETCH_MANAGE_REPORT_SCHEDULE: (state, payload) => {
		state.managereportschedule = payload
	},
	SET_FETCH_DETAILS_CUSTOMERS: (state, payload) => {
		state.allcustomerDetails = payload
	},
    SET_CUSTOMERS: (state, payload) => {
		state.customers = payload
	},
	SET_CUSTOMERS_LOADING: (state, payload) => {
		state.customersLoading = payload
	},
	SET_GET_CUSTOMERS_LOADING: (state, payload) => {
		state.customersListsLoading = payload
	},
	SET_CREATE_CUSTOMERS: (state, payload) => {
		state.createCustomer = payload
	},
	SET_CREATE_CUSTOMERS_LOADING: (state, payload) => {
		state.createCustomerLoading = payload
	},
	SET_CUSTOMERS_SAVE_ADD_LOADING: (state, payload) => {
		state.customerSaveAddLoading = payload
	},
	SET_UPDATE_CUSTOMERS: (state, payload) => {
		state.updateCustomer = payload
	},
	SET_UPDATE_CUSTOMERS_LOADING: (state, payload) => {
		state.updateSuppliersLoading = payload
	},
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}